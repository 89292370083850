import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CopyIcon = ({ text }) => {
  return (
    <>
      {text}
      <Tooltip title="Copy">
        <IconButton
          color="primary"
          aria-label="copy"
          component="label"
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(text);
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CopyIcon;
