import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import {
  Grid,
  Radio,
  FormLabel,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";

import Title from "../../common/Title";
import CustomSelect from "../../common/CustomSelect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  minWidth: "60vw",
  p: 4,
};

const MODEM_ACTIVATION_TEMPLATE = `at+qmbncfg="deactivate"
at+cgdcont=1
at+qmbncfg="AutoSel",0
at+cfun=1,1
WAIT_50
at+cgdcont=1,"IPV4V6"`;

const BLOCK_DATA_TEMPALTE = `AT+QLWCFG="startup",0
at+qfopen="../../data/fota_ip_a/update_report.ur",1
AT+CFUN=1,1
at+qflst="../../data/fota_ip_a/update_report.ur"`;

export default function GatewayAtModal({
  openAtModal,
  handleClose,
  gatewayData,
  handleSubmit,
  isLoading,
  selectedModel,
}) {
  const minModem = selectedModel.length
    ? gatewayData.reduce((min, val) => {
        if (!selectedModel.includes(val.id)) return min;
        return Math.min(min, val.modems);
      }, Number.MAX_SAFE_INTEGER)
    : 0;
  const [modem, setModem] = useState([]);
  const [atTemplate, setAtTemplate] = useState("modemActivation");
  const [command, setCommand] = useState("");

  useEffect(() => {
    switch (atTemplate) {
      case "modemActivation":
        setCommand(MODEM_ACTIVATION_TEMPLATE);
        break;
      case "blockData":
        setCommand(BLOCK_DATA_TEMPALTE);
        break;
      default:
        setCommand("");
        break;
    }
  }, [atTemplate]);

  return (
    <Modal open={openAtModal} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Box sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
          <Title variant="h5" content={`Run AT Commands`} gutterBottom />
          <Grid container spacing={5}>
            <Grid item sx={{ zIndex: 2 }}>
              <CustomSelect
                isMulti={true}
                placeholder={"All Modems"}
                isClearable={false}
                isSearchable={false}
                closeMenuOnSelect={true}
                options={[
                  ...Array.from({ length: minModem }, (x, i) => ({
                    value: i + 1,
                    label: i + 1,
                  })),
                ]}
                value={modem}
                handleChange={(val) => setModem(val)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="at-template-group">
                  Select Pre-Filled AT Command Templates
                </FormLabel>
                <RadioGroup
                  row
                  name="at-template-group"
                  value={atTemplate}
                  onChange={(e) => setAtTemplate(e.target.value)}
                >
                  <FormControlLabel
                    value="modemActivation"
                    control={<Radio />}
                    label="Modem Activation"
                  />
                  <FormControlLabel
                    value="blockData"
                    control={<Radio />}
                    label="Block Data"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="at-command-list"
                label="Enter AT Commands"
                multiline
                spellCheck="false"
                style={{ width: "100%" }}
                value={command}
                minRows={5}
                onChange={(e) => setCommand(e.target.value)}
              />
            </Grid>
          </Grid>
          <Typography
            variant="caption"
            component="div"
          >{`Special Commands:`}</Typography>

          <Typography variant="caption">{`WAIT_X - It will wait for X seconds before running next command.e.g WAIT_50 will wait 50 seconds.`}</Typography>
        </Box>
        <Grid container spacing={5} justifyContent="right" mt={1}></Grid>
        <Grid item>
          <LoadingButton
            style={{ marginLeft: "8px" }}
            variant="contained"
            color="primary"
            loading={isLoading()}
            disabled={isLoading() || !command || !command.split("\n").length}
            onClick={() => {
              const atCommands = command.split("\n");
              const data = selectedModel.map((id) => {
                const val = {
                  gateway_id: id,
                  commands: atCommands,
                };
                if (modem && modem.length) {
                  val.modems = modem.map((val) => val.value);
                }

                return val;
              });
              handleSubmit({
                data: data,
              });
            }}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Box>
    </Modal>
  );
}
