import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import { Box, Checkbox, Chip, FormControlLabel, Modal } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

import Title from "../../common/Title";
import useAuth from "../../../hooks/useAuth";
import useAlert from "../../../hooks/useAlert";
import useAxios from "../../../hooks/useAxios";
import {
  __updateMdn,
  __resetPort,
  __sendSMS,
  __resetNetwork,
} from "../../../api/mdn";
import InputFieldAutoComplete from "../../common/InputFieldAutoComplete";
import { getStatusChipColor, getStatusLabel } from "../../../constants/value";
import { isTelitSim, isUltraSim } from "../../../utils/data";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const MDNForm = ({
  initialValues,
  mode,
  mdn_id,
  userData,
  handleRefreshClick,
}) => {
  const isUpdate = mode === "update" && mdn_id;
  const { user } = useAuth();
  const initialData = user.admin
    ? { nickname: "", user_id: null }
    : { nickname: "" };
  const { setAlertMessage } = useAlert();
  const nav = useNavigate();
  const [formData, setFormData] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [message, setMessage] = useState("Wakeup Call");
  const [skipReset, setSkipReset] = useState(true);
  const [recipient, setRecipient] = useState(initialValues.mdn || "");
  const userLabels = userData.reduce(
    (obj, val) => ({ ...obj, [val.id]: val }),
    { 0: { username: "None" } }
  );
  const apiMethod = __updateMdn(mdn_id);

  const validationSchema = yup.object({
    nickname: yup
      .string("Nickname")
      .max(120, "Cannot be more than 120 characters long.")
      .nullable(),
    user_id: yup.number("User").nullable(),
  });
  const validatedData = validationSchema.noUnknown().cast(initialValues);

  const formik = useFormik({
    initialValues:
      validatedData && Object.keys(validatedData).length
        ? {
            ...validatedData,
            user_id: validatedData.user_id === null ? 0 : validatedData.user_id,
          }
        : {
            ...initialData,
          },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        obj[key] = values[key] || null;
        return obj;
      }, {});
      !user.admin && delete requestData.user_id;
      setFormData(requestData);
    },
  });

  const [response, call, loading] = useAxios({
    ...apiMethod,
    data: formData,
  });

  const [resetResponse, resetCall, resetLoading] = useAxios({
    ...__resetPort(mdn_id),
  });
  const [sendSmsResponse, sendSmsCall, sendSmsLoading] = useAxios({
    ...__sendSMS(mdn_id),
  });
  const [resetNetworkResponse, resetNetworkCall, resetNetworkLoading] =
    useAxios({
      ...__resetNetwork(mdn_id),
    });

  useEffect(() => {
    formik.setSubmitting(false);
    if (!response) return;
    if (response.status) {
      setAlertMessage({
        type: "success",
        message: `${isUpdate ? "Updated" : "Created"} successfully.`,
      });

      nav(`/mdn/${response.data.id}`);
    }
  }, [response]);

  useEffect(() => {
    if (resetResponse && resetResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Reset the port successfully",
      });
    }
  }, [resetResponse]);

  useEffect(() => {
    if (resetNetworkResponse && resetNetworkResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Network reset submitted successfully",
      });
    }
  }, [resetNetworkResponse]);

  useEffect(() => {
    if (sendSmsResponse && sendSmsResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Sent SMS successfully",
      });

      setModalState(false);
    }
  }, [sendSmsResponse]);

  useEffect(() => {
    if (formData) {
      call();
    }
  }, [formData]);

  const isLoading = () =>
    loading ||
    resetLoading ||
    sendSmsLoading ||
    formik.isSubmitting ||
    resetNetworkLoading;

  return (
    <>
      <Modal
        open={modalState}
        onClose={() => !sendSmsLoading && setModalState(false)}
      >
        <Box sx={style}>
          <Title variant="h5" content="Send SMS" gutterBottom />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel disabled>Recipient Number</InputLabel>
                <OutlinedInput
                  name="recipient"
                  label="Recipient Number"
                  id="recipient"
                  placeholder="Leave blank for default"
                  value={recipient}
                  onChange={(e) => setRecipient(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel disabled>Message</InputLabel>
                <OutlinedInput
                  name="message"
                  label="Message"
                  id="message"
                  placeholder="Leave blank for default"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Skip Reset if active"
                control={
                  <Checkbox
                    checked={skipReset}
                    onChange={(e) => setSkipReset(e.target.checked)}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} justifyContent="left">
            <Grid item>
              <LoadingButton
                style={{ marginTop: "8px" }}
                variant="contained"
                color="primary"
                loading={isLoading()}
                onClick={() =>
                  sendSmsCall({
                    data: {
                      message: message,
                      mdn: recipient || null,
                      skip_port_reset: skipReset,
                    },
                  })
                }
              >
                {"Boost Reset Port (+1 SMS)"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid
        sx={{ flexGrow: 1 }}
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth={350}
      >
        <Grid
          item
          xs={4}
          alignItems={"center"}
          display={"flex"}
          flexWrap={"wrap"}
          gap={1}
        >
          Status:{" "}
          <Chip
            label={getStatusLabel(initialValues.status)}
            size="small"
            color={getStatusChipColor(initialValues.status)}
            sx={{ mb: 2 }}
          />
          <LoadingButton
            size="small"
            onClick={() =>
              !loading &&
              !formik.isSubmitting &&
              !resetLoading &&
              !sendSmsLoading &&
              handleRefreshClick()
            }
            color={"primary"}
            variant="contained"
          >
            Refresh
          </LoadingButton>
        </Grid>
        <Grid item xs={12} minWidth={"100%"}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel disabled>ICCID</InputLabel>
            <OutlinedInput
              disabled
              name="iccid"
              label="ICCID"
              id="iccid"
              value={initialValues.iccid}
            />
          </FormControl>
        </Grid>
        {user.admin && (
          <>
            <Grid item xs={12} minWidth={"100%"}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel disabled>Gateway</InputLabel>
                <OutlinedInput
                  disabled
                  name="gateway"
                  label="Gateway"
                  id="gateway"
                  value={initialValues.gateway_id}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} minWidth={"100%"}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel disabled>Port</InputLabel>
                <OutlinedInput
                  disabled
                  name="port"
                  label="Port"
                  id="port"
                  value={initialValues.port}
                />
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={12} minWidth={"100%"}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel disabled>MDN</InputLabel>
            <OutlinedInput
              disabled
              name="mdn"
              label="MDN"
              id="mdn"
              value={initialValues.mdn}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.nickname && Boolean(formik.errors.nickname)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="nickname">Alias</InputLabel>
            <OutlinedInput
              name="nickname"
              label="Alias"
              id="nickname"
              value={formik.values.nickname}
              onChange={formik.handleChange}
            />
            {formik.touched.nickname && Boolean(formik.errors.nickname) ? (
              <FormHelperText id="nickname-error-text">
                {formik.errors.nickname}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} minWidth={"100%"}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel disabled>Scanned Successfully</InputLabel>
            <OutlinedInput
              disabled
              name="last_state"
              label="Scanned Successfully"
              id="last_state"
              value={initialValues.last_state === 0 ? "Yes" : "No"}
            />
          </FormControl>
        </Grid>

        {user.admin && (
          <Grid item xs={12} minWidth={"100%"}>
            <FormControl
              error={
                formik.touched.user_id && Boolean(formik.errors.user_id)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputFieldAutoComplete
                isSelect
                options={
                  user.id === 1
                    ? [0, ...userData.map((user) => user.id)]
                    : [...userData.map((user) => user.id)]
                }
                name="user_id"
                label="User"
                id="user_id"
                value={formik.values.user_id}
                getOptionLabel={(option) =>
                  userLabels ? `${userLabels[option]?.username}` : option
                }
                onChange={(e, val) => formik.setFieldValue("user_id", val)}
              />
              {formik.touched.user_id && Boolean(formik.errors.user_id) ? (
                <FormHelperText id="user_id-error-text">
                  {formik.errors.user_id}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        )}

        <Grid
          container
          sx={{ mt: 3 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <LoadingButton
              style={{ marginRight: "5px" }}
              onClick={() =>
                !loading &&
                !formik.isSubmitting &&
                !resetLoading &&
                !sendSmsLoading &&
                formik.submitForm()
              }
              size="large"
              loading={loading}
              variant="contained"
            >
              {isUpdate ? "Update" : "Create"}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              style={{ marginRight: "5px" }}
              size="large"
              onClick={() => nav("/mdn")}
              variant="contained"
            >
              Cancel
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ mt: 3 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          {initialValues.iccid &&
            (isUltraSim(initialValues.iccid) ||
              isTelitSim(initialValues.iccid)) && (
              <Grid item>
                <LoadingButton
                  style={{ marginRight: "5px" }}
                  onClick={() =>
                    !loading &&
                    !formik.isSubmitting &&
                    !resetLoading &&
                    !sendSmsLoading &&
                    !resetNetworkLoading &&
                    resetNetworkCall()
                  }
                  size="large"
                  loading={resetNetworkLoading}
                  variant="contained"
                >
                  Reset Network
                </LoadingButton>
              </Grid>
            )}
          <Grid item>
            <LoadingButton
              style={{ marginRight: "5px" }}
              onClick={() =>
                !loading &&
                !formik.isSubmitting &&
                !resetLoading &&
                !sendSmsLoading &&
                !resetNetworkLoading &&
                resetCall()
              }
              size="large"
              loading={resetLoading}
              variant="contained"
            >
              Reset Port
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              style={{ marginRight: "5px" }}
              onClick={() =>
                !loading &&
                !formik.isSubmitting &&
                !resetLoading &&
                !sendSmsLoading &&
                !resetNetworkLoading &&
                setModalState(true)
              }
              size="large"
              loading={sendSmsLoading}
              variant="contained"
            >
              Boost Reset Port (+1 SMS)
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MDNForm;
