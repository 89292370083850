import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ColumnHeader = ({ label, handleClick }) => {
  return (
    <>
      <Tooltip title="Copy Column">
        <IconButton
          color="primary"
          aria-label="copy column"
          component="label"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(label);
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      {label}
    </>
  );
};

export default ColumnHeader;
