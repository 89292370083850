import React from "react";
import { useNavigate } from "react-router-dom";

import { Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DataTable from "../../common/DataTable";
import StripedDataGrid from "../../common/StripedDataGrid";

import useAuth from "../../../hooks/useAuth";
import CopyIcon from "../../common/CopyIcon";
import {
  getStatusLabel,
  getStatusChipColorLabel,
} from "../../../constants/value";
import { portComparator } from "../../../utils/data";
import ColumnHeader from "../../common/ColumnHeader";
import { GridToolbarContainer, useGridApiContext } from "@mui/x-data-grid";

const MdnList = ({
  mdns,
  meta,
  client,
  status,
  loading,
  handleSort,
  assignPage,
  statusLoading,
  handleResetCall,
  disableRowClick,
  handlePageChange,
  handleHeaderClick,
  handleRefreshClick,
  handleFilterChange,
  handlePageSizeChange,
  ...props
}) => {
  const { user } = useAuth();
  const nav = useNavigate();
  const handleRowClick = (e) => {
    nav(`/mdn/${e.row.id}`);
  };

  // hack to use the apiRef until we migrate to v6 for x-data-grid
  let apiRef;
  const CustomToolbar = () => {
    apiRef = useGridApiContext();

    return <GridToolbarContainer></GridToolbarContainer>;
  };

  const columns = [
    {
      field: "mdn",
      headerName: "MDN",
      minWidth: 100,
      flex: 2,
      renderHeader: (params) =>
        client && !assignPage ? (
          <ColumnHeader
            label={"MDN"}
            handleClick={() => handleHeaderClick("mdn", apiRef)}
          />
        ) : (
          "MDN"
        ),
      renderCell: (params) =>
        params.row.mdn ? <CopyIcon text={params.row.mdn} /> : params.row.mdn,
    },
    {
      field: "iccid",
      headerName: "ICCID",
      minWidth: 200,
      flex: 3,
      renderHeader: (params) =>
        client && !assignPage ? (
          <ColumnHeader
            label={"ICCID"}
            handleClick={() => handleHeaderClick("iccid", apiRef)}
          />
        ) : (
          "ICCID"
        ),
      renderCell: (params) =>
        params.row.iccid ? (
          <CopyIcon text={params.row.iccid} />
        ) : (
          params.row.iccid
        ),
    },
    { field: "gateway_id", headerName: "Gateway", minWidth: 75, flex: 1 },
    {
      field: "port",
      headerName: "Port",
      minWidth: 50,
      flex: 1,
      sortComparator: portComparator,
    },
    { field: "nickname", headerName: "Alias", minWidth: 150 },
    {
      field: "last_state",
      headerName: "Scanned Successfully",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => (params.row.last_state === -1 ? "No" : "Yes"),
      renderCell: (params) => (
        <Chip
          label={params.row.last_state === -1 ? "No" : "Yes"}
          size="small"
          color={params.row.last_state === -1 ? "error" : "success"}
        />
      ),
    },
    {
      field: "last_modified",
      headerName: "Last Modified",
      minWidth: 200,
      filterable: false,
      flex: 2,
      valueGetter: (params) =>
        params.row.last_modified &&
        `${params.row.last_modified.toLocaleString()}`,
    },
  ];

  const columnsNormal = [
    {
      field: "mdn",
      headerName: "MDN",
      minWidth: 100,
      flex: 2,
      renderCell: (params) =>
        params.row.mdn ? (
          <CopyIcon text={params.row.mdn} />
        ) : (
          params.row.mdn
        ),
    },
    {
      field: "iccid",
      headerName: "ICCID",
      minWidth: 250,
      flex: 3,
      renderCell: (params) =>
        params.row.iccid ? (
          <CopyIcon text={params.row.iccid} />
        ) : (
          params.row.iccid
        ),
    },
    { field: "nickname", headerName: "Alias", minWidth: 150 },
    {
      field: "last_state",
      headerName: "Scanned Successfully",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.last_state === -1 ? "No" : "Yes"}
          size="small"
          color={params.row.last_state === -1 ? "error" : "success"}
        />
      ),
    },
    {
      field: "last_modified",
      headerName: "Last Modified",
      minWidth: 150,
      flex: 2,
      filterable: false,
      valueGetter: (params) =>
        params.row.last_modified &&
        `${params.row.last_modified.toLocaleString()}`,
    },
  ];

  const additionalColumn = [];
  assignPage &&
    additionalColumn.push({
      field: "username",
      headerName: "Username",
      minWidth: 100,
      flex: 2,
    });

  client &&
    mdns.length !== 1 &&
    additionalColumn.push({
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 2,
      valueGetter: (params) =>
        params.row.status ||
        (status &&
        params.row.gateway_id &&
        params.row.port &&
        status[params.row.gateway_id] &&
        status[params.row.gateway_id][params.row.port]
          ? status[params.row.gateway_id][params.row.port]
          : "Not Found"),
      renderCell: (params) => {
        const statusValue =
          params.row.status ||
          (status &&
          params.row.gateway_id &&
          params.row.port &&
          status[params.row.gateway_id] &&
          status[params.row.gateway_id][params.row.port]
            ? status[params.row.gateway_id][params.row.port]
            : "Not Found");
        if (statusLoading || loading) {
          return <LoadingButton loading={true} />;
        }
        return (
          <Chip
            label={statusValue}
            size="small"
            color={getStatusChipColorLabel(statusValue)}
          />
        );
      },
    });
  if (mdns.length === 1 && !assignPage) {
    additionalColumn.push({
      field: "status",
      headerName: "Status",
      minWidth: 350,
      flex: 4,
      filterable: false,
      renderCell: (params) => (
        <>
          <Chip
            label={getStatusLabel(params.row.status) || "Not Found"}
            size="small"
            color={getStatusChipColorLabel(
              getStatusLabel(params.row.status) || "Not Found"
            )}
          />
          <LoadingButton
            sx={{ ml: 2 }}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              !loading && handleResetCall(params.row.id);
            }}
            color={"primary"}
            variant="contained"
          >
            Reset Port
          </LoadingButton>
          <LoadingButton
            sx={{ ml: 2 }}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              !loading && handleRefreshClick();
            }}
            color={"primary"}
            variant="contained"
          >
            Refresh
          </LoadingButton>
        </>
      ),
    });
  }

  return !client ? (
    <DataTable
      loading={loading}
      data={mdns}
      columns={
        user.admin
          ? [...columns, ...additionalColumn]
          : [...columnsNormal, ...additionalColumn]
      }
      metaData={meta}
      filterMode="server"
      handleSort={handleSort}
      onFilterModelChange={handleFilterChange}
      handleRowClick={disableRowClick ? null : handleRowClick}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      {...props}
    />
  ) : (
    <StripedDataGrid
      loading={loading}
      rows={mdns}
      columns={
        user.admin
          ? [...columns, ...additionalColumn]
          : [...columnsNormal, ...additionalColumn]
      }
      onRowClick={disableRowClick ? null : handleRowClick}
      autoHeight
      components={{
        Toolbar: CustomToolbar,
      }}
    />
  );
};

export default MdnList;
