import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1D4354",
    },
    secondary: {
      main: "#6FDA44",
    },
    default: {
      main: "#f2f2f2",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colors: {
    green: "#6FDA44",
    darkBlue: "#1D4354",
    grey: "#f2f2f2",
  },
});

export default theme;
