function setToStorage(key, value) {
  localStorage.setItem(window.btoa(key), value);
}
function getFromStorage(key) {
  return localStorage.getItem(window.btoa(key));
}
function clearFromStorage(key) {
  localStorage.removeItem(window.btoa(key));
}

export { setToStorage, getFromStorage, clearFromStorage };
