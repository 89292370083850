import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import {
  Box,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import Title from "../../common/Title";
import { __addMdns } from "../../../api/mdn";
import useAlert from "../../../hooks/useAlert";
import useAxios from "../../../hooks/useAxios";
import { __getGroups } from "../../../api/group";
import { __getUserList } from "../../../api/user";
import InputFieldAutoComplete from "../../common/InputFieldAutoComplete";

const MDNCreate = () => {
  const [userResponse, userCall, userApiLoading] = useAxios(__getUserList);
  const [groupResponse, groupCall, groupApiLoading] = useAxios(__getGroups);
  const [rawData, setRawData] = useState("");
  const [linesData, setLinesData] = useState([]);
  const { setAlertMessage } = useAlert();
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState();
  const [users, setUsers] = useState([]);
  const initialData = { lines: [] };
  const nav = useNavigate();
  const [formData, setFormData] = useState(null);
  const [selectUser, setSelectUser] = useState(false);
  const [response, call, loading] = useAxios({
    ...__addMdns,
  });

  const lineValidationSchema = yup.object({
    mdn: yup
      .string("MDN")
      .max(10, "Should be exactly 10 digits long")
      .min(10, "Should be exactly 10 digits long")
      .nullable(),
    iccid: yup
      .string("iccid")
      .min(19, "Should be exactly 19 or 20 digits long")
      .max(20, "Should be exactly 19 or 20 digits long")
      .required(),
  });

  useEffect(() => {
    if (formData) {
      call({ data: { ...formData, lines: linesData } });
    }
  }, [formData]);

  useEffect(() => {
    if (!response) return;
    if (response.status) {
      setAlertMessage({ type: "success", message: "Added new sims" });
      nav(`/mdn`);
    }
  }, [response]);

  useEffect(() => {
    userCall({ params: { page_size: 1000 } });
    groupCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (userResponse && userResponse.status) {
      setUsers(userResponse.data.data);
    }
  }, [userResponse]);

  useEffect(() => {
    if (groupResponse && groupResponse.status) {
      setGroups(groupResponse.data.data);
    }
  }, [groupResponse]);

  const validateFunction = (data) => {
    const errors = {};
    const formatedLines = [];

    if (!data) {
      return errors;
    }

    const lines = data.split("\n");
    lines.some((line, idx) => {
      const data = line.split(",");
      if (1 > data.length > 2) {
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      try {
        const line_data = line.split(",");
        const obtainedData =
          line_data.length === 1
            ? { iccid: line_data[0].trim(), mdn: null }
            : { iccid: line_data[0].trim(), mdn: line_data[1].trim() || null };

        lineValidationSchema.validateSync(obtainedData);

        if (isNaN(obtainedData.iccid)) {
          throw new Error("Invalid Number");
        }
        formatedLines.push(obtainedData);
      } catch (err) {
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      return false;
    });

    if (!errors.lines) {
      setLinesData(formatedLines);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { ...initialData },
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        obj[key] = values[key] || null;
        return obj;
      }, {});

      setFormData(requestData);
    },
    validateOnChange: true,
  });

  const handleParse = (inputFile) => {
    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      setRawData(target.result);
      formik.setErrors(validateFunction(target.result));
    };
    reader.readAsText(inputFile);
  };
  const handleFileChange = (e) => {
    const allowedExtensions = ["csv"];

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        formik.setErrors({
          ...formik.errors.lines,
          upload: "Please input a csv file",
        });
        return;
      }

      handleParse(inputFile);
    }
  };

  if (loading || userApiLoading || groupApiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%" }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title
          variant="h5"
          content="Add new sims"
          sx={{ mb: 3 }}
          gutterBottom
        />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={350}
        >
          <Grid item xs={12} minWidth={"100%"}>
            <FormControlLabel
              label="Assign to a user"
              control={
                <Checkbox
                  value={selectUser}
                  onChange={(e) => setSelectUser(e.target.checked)}
                />
              }
            />
          </Grid>
          {selectUser && (
            <>
              <Grid item xs={12} minWidth={"100%"}>
                <InputFieldAutoComplete
                  isSelect
                  options={[
                    ...groups.map((group) => ({
                      ...group,
                      label: `${group.group_name}`,
                    })),
                  ]}
                  name="group"
                  label="Group"
                  id="group"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value) => setCurrentGroup(value.id)}
                />
              </Grid>
              <Grid item xs={12} minWidth={"100%"}>
                <FormControl
                  error={
                    formik.touched.user_id && Boolean(formik.errors.user_id)
                      ? true
                      : false
                  }
                  sx={{ width: "100%" }}
                >
                  <InputFieldAutoComplete
                    isSelect
                    options={[
                      ...users.reduce((acc, user) => {
                        if (user.group_id !== currentGroup) {
                          return acc;
                        }
                        const val = {
                          value: user.id,
                          label: `${user.username}${user.admin ? "*" : ""}`,
                          group_id: user.group_id,
                        };
                        acc.push(val);
                        return acc;
                      }, []),
                    ]}
                    name="user_id"
                    label="User"
                    id="user_id"
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, value) =>
                      formik.setFieldValue("user_id", value.value)
                    }
                  />
                  {formik.touched.user_id && Boolean(formik.errors.user_id) ? (
                    <FormHelperText id="user_id-error-text">
                      {formik.errors.user_id}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12} minWidth={"100%"}>
            <FormControl
              error={Boolean(formik.errors.upload) ? true : false}
              sx={{ width: "100%" }}
            >
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={{ marginRight: "1rem" }}
              >
                Upload CSV
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {Boolean(formik.errors.upload) ? (
                <FormHelperText id="upload-error-text">
                  {formik.errors.upload}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} minWidth={"100%"}>
            <InputLabel htmlFor="lines">
              {"Add in the format"}
              <br></br>
              {"ICCID,[MDN]"}
              <br></br>
              {"ICCID,[MDN]"}
            </InputLabel>
            <FormControl
              error={Boolean(formik.errors.lines) ? true : false}
              sx={{ width: "100%" }}
            >
              <TextField
                multiline
                rows={6}
                aria-label="minimum height"
                placeholder={`Add in the format\nICCID,[MDN]\nICCID,[MDN]`}
                name="lines"
                id="lines"
                value={rawData}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const data = e.target.value;
                  setRawData(data);
                  formik.setErrors(validateFunction(data));
                }}
              />
              {Boolean(formik.errors.lines) ? (
                <FormHelperText id="lines-error-text">
                  {formik.errors.lines}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <LoadingButton
                disabled={formik.errors.lines}
                onClick={() =>
                  !loading && !formik.isSubmitting && formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                {"Add SIMs"}
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                size="large"
                onClick={() => nav("/mdn")}
                loading={loading}
                variant="contained"
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MDNCreate;
