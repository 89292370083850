import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import PDUForm from "./PDUForm";
import Title from "../../../common/Title";
import useAxios from "../../../../hooks/useAxios";
import useAlert from "../../../../hooks/useAlert";
import { __getPDUById, __updatePDU } from "../../../../api/gateway";

const PDUUpdate = () => {
  const nav = useNavigate();
  const [pdu, setPdu] = useState([]);
  const { setAlertMessage } = useAlert();
  const { pdu_id } = useParams();
  const [response, call, apiLoading] = useAxios({
    ...__getPDUById(pdu_id),
  });
  const [updateResponse, updateCall, updateApiLoading] = useAxios({
    ...__updatePDU(pdu_id),
  });

  useEffect(() => {
    call();
  }, []);

  const handleFormSubmit = (data) => {
    updateCall({ data: data });
  };

  useEffect(() => {
    if (response && response.status) {
      setPdu(response.data);
      return;
    }

    if (response && !response.status) {
      nav("/pdu");
    }
  }, [response]);

  useEffect(() => {
    if (!updateResponse) return;
    if (updateResponse.status) {
      setAlertMessage({ type: "success", message: `Updated successfully.` });

      call();
    }
  }, [updateResponse]);

  if (apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box
      sx={{ width: "100%" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Title variant="h5" content="PDU Update" sx={{ mb: 3 }} gutterBottom />
      <PDUForm
        mode="update"
        initialValues={pdu}
        pduId={pdu.id}
        handleSubmit={handleFormSubmit}
        loading={apiLoading || updateApiLoading}
      />
    </Box>
  );
};

export default PDUUpdate;
