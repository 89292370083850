import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format, intervalToDuration } from "date-fns";

import { Button, Chip, Grid, Tooltip, Typography } from "@mui/material";

import DataTable from "../../../common/DataTable";
import useAxios from "../../../../hooks/useAxios";
import useAlert from "../../../../hooks/useAlert";
import { __stopSlotScanRotation } from "../../../../api/gateway";
import { PENDING_SLOT_STATUS } from "../../../../constants/value";

const ScanHistoryList = ({
  meta,
  loading,
  scanHistory,
  triggerCall,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const { setAlertMessage } = useAlert();
  const nav = useNavigate();
  const [stopResponse, stopCall, stopLoading] = useAxios({
    ...__stopSlotScanRotation,
  });
  const handleRowClick = (e, details) =>
    nav(`/gateway/${e.row.gateway_id}/details`);

  useEffect(() => {
    if (stopResponse && stopResponse.status) {
      setAlertMessage({
        type: "success",
        message: stopResponse.data.response,
      });
      triggerCall();
    }
  }, [stopResponse]);

  const columns = [
    { field: "id", headerName: "ID", minWidth: 70, flex: 1 },
    {
      field: "gateway_id",
      headerName: "Gateway Id",
      flex: 1,
      renderCell: (params) => params.row.gateway_id,
    },
    {
      field: "slot",
      headerName: "Slot",
      flex: 1,
      renderCell: (params) => params.row.slot,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <React.Fragment>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Chip
                label={params.row.status}
                size="small"
                color={
                  params.row.status === "COMPLETE"
                    ? "secondary"
                    : params.row.status === "IN_PROGRESS"
                    ? "primary"
                    : "default"
                }
              />
            </Grid>

            <Grid item>
              {params.row.trial_count && (
                <Tooltip title="Trial Count">
                  <Chip label={params.row.trial_count} size="small" />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 200,
      flex: 2,
      renderCell: (params) => (
        <Typography>
          {format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}
        </Typography>
      ),
    },
    {
      field: "started_at",
      headerName: "Started At",
      minWidth: 200,
      flex: 2,
      renderCell: (params) =>
        params.row.started_at && (
          <Typography>
            {format(new Date(params.row.started_at), "MMMM d, y hh:mm a")}
          </Typography>
        ),
    },
    {
      field: "completed_at",
      headerName: "Completed At",
      minWidth: 200,
      flex: 2,
      renderCell: (params) =>
        params.row.completed_at && (
          <Typography>
            {format(new Date(params.row.completed_at), "MMMM d, y hh:mm a")}
          </Typography>
        ),
    },
    {
      field: "timeTaken",
      headerName: "Time Taken",
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        const diff = intervalToDuration({
          start: new Date(params.row.started_at),
          end: new Date(params.row.completed_at),
        });

        return (
          params.row.completed_at && (
            <Chip
              label={`${diff.hours} h ${diff.minutes} m ${diff.seconds} s`}
              size="small"
              color="primary"
            />
          )
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      renderCell: (params) =>
        PENDING_SLOT_STATUS.includes(params.row.status) && (
          <Button
            variant="contained"
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              stopCall({ url: `scan_history/stop/${params.row.id}` });
            }}
          >
            Stop
          </Button>
        ),
    },
  ];
  return (
    <DataTable
      loading={loading || stopLoading}
      data={scanHistory}
      columns={columns}
      metaData={meta}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
    />
  );
};

export default ScanHistoryList;
