import { format } from "date-fns";
import styled from "@emotion/styled";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Chip,
  alpha,
  Stack,
  Button,
  Typography,
  gridClasses,
} from "@mui/material";

import useAxios from "../../../../hooks/useAxios";
import {
  __getRotateSlotHistoryExport,
  __getRotateSlotHistoryStatus,
} from "../../../../api/gateway";
import Title from "../../../common/Title";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${gridClasses.row}`]: {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      cursor: "pointer",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const columns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "slot_gw_comp", headerName: "Slot", flex: 1 },
  { field: "iccid", headerName: "ICCID", flex: 3 },
  { field: "mdn", headerName: "MDN", flex: 2 },
  {
    field: "status",
    headerName: "Status",
    flex: 2,
    renderCell: (params) => (
      <Chip
        label={params.row.status}
        size="small"
        color={params.row.status === "Registered" ? "secondary" : "default"}
      />
    ),
  },
  {
    field: "created_at",
    headerName: "Created At",
    minWidth: 200,
    flex: 2,
    renderCell: (params) => (
      <Typography>
        {format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}
      </Typography>
    ),
  },
];

const RotateSlotStatus = (props) => {
  const [rotateStatus, setRotateStatus] = useState([]);
  const { rotate_slot_id } = useParams();

  const [response, call, loading] = useAxios({
    ...__getRotateSlotHistoryStatus(rotate_slot_id),
  });
  const [exportResponse, exportCall, exportLoading] = useAxios({
    ...__getRotateSlotHistoryExport(rotate_slot_id),
  });

  useEffect(() => {
    if (response && response.status) {
      setRotateStatus(response.data);
    }
  }, [response]);

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, "rotate_history_export.csv");
    }
  }, [exportResponse]);

  return loading ? (
    <Title content="Please wait..." variant="subtitle2" gutterBottom />
  ) : (
    <>
      <Box mb={2} pt={2}>
        <Typography variant="h5" align="left">
          {`Rotate Slot Status, Gateway: ${
            rotateStatus?.length && rotateStatus[0].gateway_id
          }`}
        </Typography>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              exportCall({
                responseType: "blob",
              })
            }
          >
            Export
          </Button>
        </Stack>
      </Box>

      <StripedDataGrid
        rows={rotateStatus}
        columns={columns}
        disableSelectionOnClick
        autoHeight
      />
    </>
  );
};

export default RotateSlotStatus;
