import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import useAuth from "../hooks/useAuth";

const SuperAdminRoutes = () => {
  const { auth, user } = useAuth();
  if (!auth.token) {
    return <Navigate to="/login" />;
  }
  if (!user.admin || user.id !== 1) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default SuperAdminRoutes;
