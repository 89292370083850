import React from "react";

import useAuth from "../../../hooks/useAuth";
import CodeChip from "../../common/StyledChip";
import DataTable from "../../common/DataTable";

const SMSTableMobile = ({
  sms,
  meta,
  loading,
  onRowClick,
  selectedItems,
  handlePageChange,
  onSelectionChange,
  handleAllSelectClick,
  handlePageSizeChange,
  ...props
}) => {
  const { user } = useAuth();

  const normalUserExcludeCol = ["gateway_id", "port"];

  const columns = [
    {
      field: "gateway_id",
      headerName: "Gateway",
      flex: 1,
      renderCell: (props) => {
        const rowData = props.row;

        return !rowData.processed ? (
          rowData.gateway_id
        ) : (
          <strong>{rowData.gateway_id}</strong>
        );
      },
    },
    {
      field: "port",
      headerName: "Port",
      flex: 1,
      renderCell: (props) => {
        const rowData = props.row;
        return !rowData.processed ? (
          rowData.port
        ) : (
          <strong>{rowData.port}</strong>
        );
      },
    },
    {
      field: "time_stamp",
      headerName: "Time",
      minWidth: 200,
      flex: 3,
      renderCell: (props) => {
        const rowData = props.row;
        if (!rowData.time_stamp) {
          return "";
        }

        const formattedTime = new Date(rowData.time_stamp).toLocaleString();

        return !rowData.processed ? (
          formattedTime
        ) : (
          <strong>{formattedTime}</strong>
        );
      },
    },
    {
      field: "ani",
      headerName: "Source",
      minWidth: 200,
      flex: 3,
      renderCell: (props) => {
        const rowData = props.row;
        return !rowData.processed ? (
          rowData.ani
        ) : (
          <strong>{rowData.ani}</strong>
        );
      },
    },
    {
      field: "dnis",
      headerName: "MDN",
      minWidth: 200,
      flex: 3,
      renderCell: (props) => {
        const rowData = props.row;
        const value =
          rowData.dnis + (rowData.nickname ? ` (${rowData.nickname})` : "");
        return !rowData.processed ? value : <strong>{value}</strong>;
      },
    },
    {
      field: "group_id",
      headerName: "Owner",
      minWidth: 200,
      flex: 3,
      renderCell: (props) => {
        const rowData = props.row;
        const value = rowData.group_id
          ? `${rowData.group.group_name}:${rowData.user.username}`
          : "";
        return !rowData.processed ? value : <strong>{value}</strong>;
      },
    },
    {
      field: "txt",
      headerName: "Message",
      minWidth: 500,
      flex: 6,
      renderCell: (props) => {
        const rowData = props.row;

        return !rowData.processed ? (
          <CodeChip text={rowData.txt} />
        ) : (
          <strong>
            <CodeChip text={rowData.txt} />
          </strong>
        );
      },
    },
    {},
  ];

  return (
    <>
      <DataTable
        loading={loading}
        data={sms}
        columns={
          user.admin
            ? columns
            : columns.filter((val) => normalUserExcludeCol.includes(val))
        }
        metaData={{ ...meta, total: 10 * meta.page_size }}
        handleRowClick={(e) => onRowClick(e.row)}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        {...props}
      />
    </>
  );
};

export default SMSTableMobile;
