import axios from "axios";

import { url } from "./environment";
import { getFromStorage, setToStorage } from "../utils/storage";
import { resetAuthData } from "../reducer/auth";

const AUTHORIZATION_HEADER = "Authorization";
/**
 * Build authorization header
 *
 * @param {string} accessToken
 * @returns {string}
 */
function getAuthorizationHeader(accessToken) {
  return `Bearer ${accessToken}`;
}

const refreshTokenHttp = axios.create({
  baseURL: `${url.apiUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
});

const redirectToLogin = () => {
  window.location.href = `/`;
};

/**
 * Interceptor to add Access Token header for all requests.
 *
 * @param {object} request
 * @returns {object}
 */
function authorizationInterceptor(request) {
  const accessToken = getFromStorage("ACCESS_TOKEN_DATA");
  const userId = getFromStorage("USER_DATA");

  if (accessToken && !request.headers[AUTHORIZATION_HEADER]) {
    request.headers[AUTHORIZATION_HEADER] = getAuthorizationHeader(accessToken);
  }

  if (userId && JSON.parse(userId)) {
    request.headers["X-Request-ID"] = JSON.parse(userId).username;
  }

  return request;
}

/**
 * Interceptor to refresh Authorization header.
 *
 * @param {object} error
 * @returns {object}
 */
export async function unauthorizedResponseHandlerInterceptor(error) {
  if (!error.response) {
    return Promise.reject(error);
  }

  const originalRequest = error.config;
  const code = error.response.status;
  const message = error.response.data?.detail;

  // TODO need to fix this
  // also look http.js and auth.js refresh()
  if (code === 401) {
    const refreshToken = getFromStorage("REFRESH_TOKEN_DATA");

    if (!refreshToken) {
      return redirectToLogin();
    }

    try {
      const { data } = await refreshTokenHttp.post(
        `/auth/refresh?refresh=${refreshToken}`
      );
      setToStorage("ACCESS_TOKEN_DATA", data.access_token);
      originalRequest.headers[AUTHORIZATION_HEADER] = getAuthorizationHeader(
        data.access_token
      );

      return http.request(originalRequest);
    } catch (e) {
      console.log(e);
      // return redirectToLogin();
    }
  }

  if (
    (code === 401 && message === "Invalid token") ||
    message === "Invalid token"
  ) {
    await resetAuthData();
  }

  return Promise.reject(error);
}

const http = axios.create({
  baseURL: `${url.apiUrl}`,
});

http.interceptors.request.use(authorizationInterceptor);
http.interceptors.response.use(
  (response) => response,

  unauthorizedResponseHandlerInterceptor
);

export default http;
