import { useParams } from "react-router-dom";
import fileDownload from "js-file-download";

import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, Chip, Grid, Stack } from "@mui/material";
import {
  gridFilteredSortedRowIdsSelector,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";

import Title from "../../common/Title";
import DataTable from "../../common/DataTable";
import ColumnHeader from "../../common/ColumnHeader";

import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import { copyData } from "../../../utils/copy";
import { portComparator } from "../../../utils/data";
import { getStatusChipColorLabel } from "../../../constants/value";
import { __getMDNExport, __getMDNExportData } from "../../../api/mdn";

const MDNExportView = () => {
  const { id } = useParams();
  const [exportData, setExportData] = useState([]);
  const { setAlertMessage } = useAlert();

  const [response, call, loading] = useAxios({ ...__getMDNExportData(id) });
  const [fileResponse, fileCall, fileLoading] = useAxios({
    ...__getMDNExport,
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setExportData(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (fileResponse && fileResponse.status) {
      fileDownload(fileResponse.data, `export-${new Date().toISOString()}.csv`);
    }

    if (fileResponse && !fileResponse.status) {
      setAlertMessage({
        type: "error",
        message: "File not found",
      });
    }
  }, [fileResponse]);

  // hack to get the apiref for data grid till we migrate to v6
  let apiRef;
  const CustomToolbar = () => {
    apiRef = useGridApiContext();

    return <GridToolbarContainer></GridToolbarContainer>;
  };

  const columns = [
    {
      field: "mdn",
      headerName: "MDN",
      minWidth: 100,
      flex: 2,
      renderHeader: (params) => (
        <ColumnHeader
          label={"MDN"}
          handleClick={() => {
            const gridIds = gridFilteredSortedRowIdsSelector(apiRef);
            copyData(
              exportData((val) => gridIds.includes(val.id)),
              ["mdn"]
            );
          }}
        />
      ),
    },
    {
      field: "iccid",
      headerName: "ICCID",
      minWidth: 200,
      flex: 3,
      renderHeader: (params) => (
        <ColumnHeader
          label={"ICCID"}
          handleClick={() => {
            const gridIds = gridFilteredSortedRowIdsSelector(apiRef);
            copyData(
              exportData.filter((val) => gridIds.includes(val.id)),
              ["iccid"]
            );
          }}
        />
      ),
    },
    { field: "gateway_id", headerName: "Gateway", minWidth: 75, flex: 1 },
    {
      field: "port",
      headerName: "Port",
      minWidth: 50,
      flex: 1,
      sortComparator: portComparator,
    },
    { field: "nickname", headerName: "Alias", minWidth: 150 },
    {
      field: "last_state",
      headerName: "Scanned Successfully",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => (params.row.last_state === -1 ? "No" : "Yes"),
      renderCell: (params) => (
        <Chip
          label={params.row.last_state === -1 ? "No" : "Yes"}
          size="small"
          color={params.row.last_state === -1 ? "error" : "success"}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 2,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          size="small"
          color={getStatusChipColorLabel(params.row.status)}
        />
      ),
    },
    {
      field: "last_modified",
      headerName: "Last Modified",
      minWidth: 200,
      filterable: false,
      flex: 2,
      valueGetter: (params) =>
        params.row.last_modified &&
        `${params.row.last_modified.toLocaleString()}`,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="MDN Export List" />
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item sx={{ mb: "5px" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              disabled={loading || fileLoading}
              onClick={() =>
                fileCall({
                  responseType: "blob",
                  url: `mdn/export_mdn/${id}`,
                })
              }
            >
              Download Data
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <DataTable
        loading={loading}
        data={exportData}
        columns={columns}
        paginationMode={"client"}
        handleRowClick={null}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </Box>
  );
};

export default MDNExportView;
