import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Chip, Stack, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import GatewayModal from "./GatewayModal";
import SMSTestModal from "./SmsTestModal";
import StripedDataGrid from "../../common/StripedDataGrid";

import {
  __save,
  __scan,
  __reboot,
  __rotateSlot,
  __pduPowerCycle,
  __gatewayDetails,
  __testSmsReceive,
  __getGatewayById,
  __gatewayEnableSlots,
  __disable_not_in_use,
  __gatewayDetailsExport,
} from "../../../api/gateway";
import useAuth from "../../../hooks/useAuth";
import useAlert from "../../../hooks/useAlert";
import useAxios from "../../../hooks/useAxios";
import { portComparator } from "../../../utils/data";
import { gatewayOperation } from "../../../constants/value";

const columns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "iccid", headerName: "ICCID", flex: 1.5, minWidth: 200 },
  { field: "mdn", headerName: "MSISDN", flex: 1.5, minWidth: 200 },
  {
    field: "port",
    headerName: "Port",
    flex: 1,
    sortComparator: portComparator,
  },
  {
    field: "last_state",
    headerName: "Scanned Successfully",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <Chip
        label={params.row.last_state}
        size="small"
        color={params.row.last_state === "No" ? "error" : "success"}
      />
    ),
  },

  {
    field: "last_modified",
    headerName: "Last Modified",
    minWidth: 320,
    renderCell: (params) =>
      params.row.last_modified && (
        <Typography>
          {new Date(params.row.last_modified).toLocaleString()}
        </Typography>
      ),
    flex: 2,
  },
];

const GatewayHistoryDetails = (props) => {
  const [gatewayData, setGatewayData] = useState([]);
  const [gatewayInfo, setGatewayInfo] = useState([]);
  const { setAlertMessage } = useAlert();
  const { gateway_id } = useParams();
  const { user } = useAuth();

  const [modalType, setModalType] = useState(false);
  const [openTestModal, setOpenTestModal] = useState(false);
  const [response, call, loading] = useAxios({
    ...__gatewayDetails(gateway_id),
  });
  const [gatewayResponse, gatewayCall, gatewayLoading] = useAxios({
    ...__getGatewayById(gateway_id),
  });
  const [rebootResponse, rebootCall, rebootLoading] = useAxios({ ...__reboot });
  const [scanResponse, scanCall, scanLoading] = useAxios({ ...__scan });
  const [saveResponse, saveCall, saveLoading] = useAxios({ ...__save });
  const [rotateSlotResponse, rotateSlotCall, rotateSlotLoading] = useAxios({
    ...__rotateSlot,
  });
  const [disableResponse, disableCall, disableLoading] = useAxios({
    ...__disable_not_in_use,
  });
  const [exportResponse, exportCall, exportLoading] = useAxios({
    ...__gatewayDetailsExport(gateway_id),
  });
  const [enableResponse, enableCall, enableLoading] = useAxios({
    ...__gatewayEnableSlots,
  });
  const [cycleResponse, cycleCall, cycleLoading] = useAxios({
    ...__pduPowerCycle,
  });

  const [testResponse, testCall, testLoading] = useAxios({
    ...__testSmsReceive(gateway_id),
  });

  useEffect(() => {
    if (response && response.status) {
      setGatewayData(
        response.data.data.map((val) => ({
          ...val,
          last_state: val.last_state === -1 ? "No" : "Yes",
        }))
      );
    }
  }, [response]);

  useEffect(() => {
    if (gatewayResponse && gatewayResponse.status) {
      setGatewayInfo(gatewayResponse.data);
    }
  }, [gatewayResponse]);

  useEffect(() => {
    gatewayCall();
    call();
  }, []);

  useEffect(() => {
    if (rebootResponse && rebootResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Reboot started!",
      });
    }
  }, [rebootResponse]);

  useEffect(() => {
    if (testResponse && testResponse.status) {
      setAlertMessage({
        type: "success",
        message: `SMS test started! Batch ID: ${testResponse.data.batch_id}`,
      });
      setOpenTestModal(false);
    }
  }, [testResponse]);

  useEffect(() => {
    if (scanResponse && scanResponse.status) {
      setAlertMessage({
        type: "success",
        message: scanResponse.data?.message,
      });
      setModalType(false);
    }
  }, [scanResponse]);

  useEffect(() => {
    if (enableResponse && enableResponse.status) {
      setAlertMessage({
        type: "success",
        message: enableResponse.data && JSON.stringify(enableResponse.data),
      });
      setModalType(false);
    }
  }, [enableResponse]);

  useEffect(() => {
    if (rotateSlotResponse && rotateSlotResponse.status) {
      setAlertMessage({
        type: "success",
        message: rotateSlotResponse.data?.message,
      });
      setModalType(false);
    }
  }, [rotateSlotResponse]);

  useEffect(() => {
    if (saveResponse && saveResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Gateway settings saved successfully",
      });
    }
  }, [saveResponse]);

  useEffect(() => {
    if (disableResponse && disableResponse.status) {
      setAlertMessage({
        type: "success",
        message: disableResponse.data,
      });
    }
  }, [disableResponse]);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, `gateway_export_${gateway_id}.csv`);
    }
  }, [exportResponse]);

  useEffect(() => {
    if (enableResponse && enableResponse.status) {
      setAlertMessage({
        type: "success",
        message: enableResponse.data && JSON.stringify(enableResponse.data),
      });
      setModalType(false);
    }
  }, [enableResponse]);

  useEffect(() => {
    if (cycleResponse && cycleResponse.status) {
      setAlertMessage({
        type: "success",
        message: "PDU operation initiated successfully",
      });
    }
  }, [cycleResponse]);

  const modalProperties = {
    [gatewayOperation.Enable]: {
      handleSubmit: enableCall,
      loading: enableLoading,
    },
    [gatewayOperation.Scan]: {
      handleSubmit: scanCall,
      loading: scanLoading,
    },
    [gatewayOperation.Rotate]: {
      handleSubmit: rotateSlotCall,
      loading: rotateSlotLoading,
    },
  };

  const rebootDevices = () => {
    rebootCall({ data: { ids: [gateway_id] } });
  };

  const rotateSlots = () => {
    rotateSlotCall({ data: { ids: [gateway_id] } });
  };

  const saveDevices = () => {
    saveCall({ data: { ids: [gateway_id] } });
  };

  const disableDevices = () => {
    disableCall({ data: { ids: [gateway_id] } });
  };

  const isLoading = () => {
    return (
      loading ||
      rebootLoading ||
      scanLoading ||
      saveLoading ||
      disableLoading ||
      cycleLoading
    );
  };

  return (
    <>
      {response &&
        modalType &&
        Object.keys(gatewayOperation).includes(modalType) && (
          <GatewayModal
            gatewayData={[gatewayInfo]}
            handleClose={setModalType}
            handleSubmit={modalProperties[modalType]?.handleSubmit}
            loading={modalProperties[modalType]?.loading}
            operation={modalType}
            selectedModel={[gatewayInfo.id]}
          />
        )}
      {response && openTestModal && (
        <SMSTestModal
          gatewayData={gatewayInfo}
          open={openTestModal}
          handleClose={setOpenTestModal}
          handleSubmit={testCall}
          loading={testLoading}
        />
      )}
      <Box mb={2} pt={2}>
        <Typography variant="h5" align="left">
          {props.title}
        </Typography>
      </Box>
      <Box pb={2}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  exportCall({
                    responseType: "blob",
                  })
                }
              >
                Export
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading()}
                onClick={() => setModalType(gatewayOperation.Enable)}
              >
                Enable Slots
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading()}
                onClick={disableDevices}
              >
                Disable Not In Use Slots
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading()}
                onClick={saveDevices}
              >
                Save
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading()}
                onClick={rebootDevices}
              >
                Reboot
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading()}
                onClick={() => setModalType(gatewayOperation.Scan)}
              >
                Scan
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading()}
                onClick={() => setModalType(gatewayOperation.Rotate)}
              >
                Rotate Slots
              </Button>
            </Stack>
          </Grid>
          {user.id === 1 && (
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading()}
                  onClick={() => setOpenTestModal(true)}
                >
                  Test SMS Receive
                </Button>
              </Stack>
            </Grid>
          )}
          {gatewayInfo && gatewayInfo.pdu_id && gatewayInfo.pdu_load_id && (
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    cycleCall({ url: `gateway/${gatewayInfo.id}/cycle_power` });
                  }}
                >
                  Power Cycle
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={(e) => {
                    e.stopPropagation();
                    cycleCall({ url: `gateway/${gatewayInfo.id}/on_power` });
                  }}
                >
                  On
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    cycleCall({ url: `gateway/${gatewayInfo.id}/off_power` });
                  }}
                >
                  Off
                </Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>

      <StripedDataGrid
        rows={gatewayData}
        columns={columns}
        disableSelectionOnClick
        autoHeight
      />
    </>
  );
};

GatewayHistoryDetails.defaultProps = {
  title: "Gateway Details",
};

export default GatewayHistoryDetails;
