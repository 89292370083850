import React from "react";
import { useNavigate } from "react-router-dom";

import { Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DataTable from "../../../common/DataTable";

const PDUList = ({
  pdus,
  loading,
  status,
  statusLoading,
}) => {
  const nav = useNavigate();
  const handleRowClick = (e) => {
    nav(`/pdu/${e.row.id}`);
  };

  const columns = [
    { field: "id", headerName: "Id", flex: 1 },
    {
      field: "host",
      headerName: "Host",
      flex: 3,
      minWidth: 300,
      renderCell: (params) => (
        <div onClick={(e) => e.stopPropagation()}>
          <a href={params.row.host} target="_blank">
            {params.row.host}
          </a>
        </div>
      ),
    },
    { field: "username", headerName: "Username", flex: 2, minWidth: 100 },
    { field: "password", headerName: "Password", flex: 2, minWidth: 100 },
    {
      field: "is_online",
      headerName: "Status",
      flex: 2,
      minWidth: 100,
      valueGetter: (params) => status[params.row.id],
      renderCell: (params) => {
        if (statusLoading || loading) {
          return <LoadingButton loading={true} />;
        }
        return (
          <Chip
            label={status[params.row.id] ? "Online" : "Offline"}
            size="small"
            color={status[params.row.id] ? "secondary" : "primary"}
          />
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => (
        <Chip label={params.row.type} size="small" color={"primary"} />
      ),
    },
  ];

  return (
    <DataTable
      loading={loading}
      data={pdus}
      columns={columns}
      paginationMode={"client"}
      sortingMode={"client"}
      handleRowClick={handleRowClick}
    />
  );
};

export default PDUList;
