import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import GroupForm from "./GroupForm";
import Title from "../../common/Title";
import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import { __createGroup } from "../../../api/group";

const GroupCreate = () => {
  const nav = useNavigate();
  const { setAlertMessage } = useAlert();
  const [response, call, apiLoading] = useAxios({
    ...__createGroup,
  });

  const handleFormSubmit = (data) => {
    call({ data: data });
  };

  useEffect(() => {
    if (!response) return;
    if (response.status)
      setAlertMessage({
        type: "success",
        message: `Created successfully.`,
      });

    if (response.status) {
      nav(`/group/${response.data.id}`);
    }
  }, [response]);

  return (
    <Box
      sx={{ width: "100%" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Title variant="h5" content="Group Create" sx={{ mb: 3 }} gutterBottom />
      <GroupForm
        mode="create"
        handleSubmit={handleFormSubmit}
        loading={apiLoading}
      />
    </Box>
  );
};

export default GroupCreate;
