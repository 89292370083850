import React from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";
import DataTable from "../../common/DataTable";

const superAdminColumns = [
  { field: "id", headerName: "Id", flex: 1 },
  { field: "group_name", headerName: "Name", flex: 2, minWidth: 150 },
  { field: "api_key", headerName: "API Key", flex: 1, minWidth: 150 },
  {
    field: "track_port_status",
    headerName: "Track Port Status",
    flex: 1,
    sortable: false,
  },
  { field: "forward_url", headerName: "Forward URL", flex: 3, sortable: false },
];

const adminColumns = [
  { field: "id", headerName: "Id", flex: 1 },
  { field: "group_name", headerName: "Name", flex: 2, minWidth: 150 },
  { field: "api_key", headerName: "API Key", flex: 1, minWidth: 150 },
  {
    field: "track_port_status",
    headerName: "Track Port Status",
    flex: 1,
    sortable: false,
  },
  { field: "forward_url", headerName: "Forward URL", flex: 3, sortable: false },
  {
    field: "max_number_change_allowed",
    headerName: "Max Change Allowed",
    flex: 1,
    sortable: false,
  },
];

const GroupList = ({
  groups,
  meta,
  loading,
  handleSort,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const { user } = useAuth();
  const nav = useNavigate();
  const handleRowClick = (e) => {
    nav(`/group/${e.row.id}`);
  };

  return (
    <DataTable
      loading={loading}
      data={groups}
      columns={user.id === 1 ? superAdminColumns : adminColumns}
      metaData={meta}
      handleRowClick={user.admin && handleRowClick}
      handleSort={handleSort}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
    />
  );
};

export default GroupList;
