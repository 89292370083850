import React from "react";
import { CellMeasurer } from "react-virtualized";

const DynamicColumn = ({
  dataKey,
  parent,
  rowIndex,
  columnIndex,
  cellData,
  children,
  cache,
}) => {
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={columnIndex}
      key={dataKey}
      parent={parent}
      rowIndex={rowIndex}
    >
      {({ measure }) => (
        <div className="cell-measurer" onLoad={measure}>
          {children}
        </div>
      )}
    </CellMeasurer>
  );
};

export default DynamicColumn;
