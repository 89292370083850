import React from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";

import Title from "../../../common/Title";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function RemoveModal({
  open,
  handleClose,
  handleSubmit,
  loading,
  rule,
}) {
  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Title variant="h5" content={`Remove Rule`} gutterBottom />
        <Typography>
          Remove rule {rule[".id"]} for address {rule.address} assigned to{" "}
          {rule["list"]}
        </Typography>
        <Grid container>
          <Grid container spacing={5} justifyContent="left"></Grid>
          <Grid item>
            <LoadingButton
              style={{ marginLeft: "8px" }}
              variant="contained"
              color="primary"
              loading={loading}
              disabled={loading}
              onClick={() =>
                handleSubmit({ url: `group/address_rules/${rule[".id"]}` })
              }
            >
              {`Delete rule`}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
