const __getSms = {
  method: "GET",
  url: "sms/",
};

const __bulkUpdate = {
  method: "POST",
  url: "sms/bulk_update",
};

const __updateSmsStatus = {
  method: "POST",
};

const __exportSMS = {
  method: "GET",
  url: "sms/export",
};

export { __getSms, __bulkUpdate, __updateSmsStatus, __exportSMS };
