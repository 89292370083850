import React, { useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";
import { Grid, InputLabel, OutlinedInput } from "@mui/material";

import Title from "../../common/Title";
import CustomSelect from "../../common/CustomSelect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function SMSTestModal({
  open,
  handleClose,
  gatewayData,
  handleSubmit,
  loading,
}) {
  const minSlot = gatewayData.ports;
  const [slot, setSlot] = useState();
  const [waitTime, setWaitTime] = useState(120);

  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Title variant="h5" content={`Sms Test`} gutterBottom />
        <Grid container spacing={5}>
          <Grid item>
            <CustomSelect
              helperText={"Slot"}
              placeholder={"Slot"}
              isClearable={false}
              isSearchable={false}
              closeMenuOnSelect={true}
              options={[
                ...Array.from({ length: minSlot + 1 }, (x, i) => ({
                  value: i,
                  label: i === 0 ? "All" : i,
                })),
              ]}
              defaultValue={{ value: slot, label: slot === 0 ? "All" : slot }}
              handleChange={(val) => setSlot(val.value)}
            />
          </Grid>
          {slot === 0 && (
            <Grid item>
              <InputLabel htmlFor="wait_time">Rotate Wait Time (in seconds)</InputLabel>
              <OutlinedInput
                name="wait_time"
                label="Rotate Wait Time"
                id="wait_time"
                value={waitTime}
                type="number"
                onChange={(e) => setWaitTime(e.target.value)}
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid container spacing={5} justifyContent="left"></Grid>
          <Grid item>
            <LoadingButton
              style={{ marginLeft: "8px" }}
              variant="contained"
              color="primary"
              loading={loading}
              disabled={loading || isNaN(slot)}
              onClick={() =>
                handleSubmit({
                  data: {
                    wait_time_between_slot: waitTime,
                    slot: slot,
                  },
                })
              }
            >
              {`Test SMS`}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
