import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";

import Title from "../../common/Title";
import InputFieldAutoComplete from "../../common/InputFieldAutoComplete";

import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import { __getGroups } from "../../../api/group";
import {
  __createGateway,
  __updateGateway,
  __getPDUs,
} from "../../../api/gateway";

const GatewayForm = ({ initialValues, mode, gatewayId }) => {
  const initialData = {
    ip_address: "",
    nickname: "",
    modems: "",
    ports: "",
    username: "",
    password: "",
    group_id: 0,
    pdu_id: null,
    pdu_load_id: null,
    note: "",
    reboot_interval: null,
  };
  const { setAlertMessage } = useAlert();
  const isUpdate = mode === "update" && gatewayId;
  const nav = useNavigate();
  const [formData, setFormData] = useState(null);
  const apiMethod = isUpdate ? __updateGateway(gatewayId) : __createGateway;
  const [groups, setGroups] = useState([]);
  const [pdus, setPdus] = useState([]);
  const [groupResponse, groupCall, groupApiLoading] = useAxios(__getGroups);
  const [pduResponse, pduCall, pduApiLoading] = useAxios(__getPDUs);
  const [labels, setLabels] = useState([]);
  const [pduLabels, setPduLabels] = useState([]);

  const validationSchema = yup.object({
    ip_address: yup
      .string("IP Address")
      .required("IP address is required")
      .max(80, "Cannot be more than 80 characters long."),
    nickname: yup
      .string("Nickname")
      .required("Nickname is required")
      .max(80, "Cannot be more than 80 characters long."),
    modems: yup.number("Modems").required("Modem is required").nullable(),
    ports: yup.number("Ports").required("Ports is required").nullable(),
    username: yup
      .string("IP Address")
      .required("IP address is required")
      .max(80, "Cannot be more than 80 characters long."),
    password: yup
      .string("Password")
      .required("Password is required")
      .max(80, "Cannot be more than 80 characters long."),
    group_id: yup.number("Group"),
    pdu_id: yup.number("PDU Id").nullable(),
    pdu_load_id: yup.number("PDU Load Id").nullable(),
    note: yup.string("Note").nullable(),
    reboot_interval: yup.number("Reboot Interval").nullable(),
    slot_rotation_wait_time: yup.number("Slot rotation wait time").nullable(),
    slot_rotation_schedule: yup.string("Slot rotation time").nullable(),
  });

  const formik = useFormik({
    initialValues:
      initialValues && Object.keys(initialValues).length
        ? {
            ...initialValues,
            group_id: initialValues.group_id ? initialValues.group_id : 0,
          }
        : { ...initialData },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (
          Object.keys(validationSchema.fields).includes(key) &&
          values[key] !== null
        ) {
          obj[key] = values[key];
        }
        if (key === "group_id" && values[key] === 0) {
          obj[key] = null;
        }

        if (
          [
            "pdu_id",
            "pdu_load_id",
            "reboot_interval",
            "slot_rotation_wait_time",
            "slot_rotation_schedule",
          ].includes(key) &&
          !values[key]
        ) {
          obj[key] = null;
        }
        
        // to handle the utc to local time issue, assuming everything is utc
        if (key === "slot_rotation_schedule" && values[key]) {
          obj[key] =
            format(values[key], "yyyy-MM-dd") +
            "T" +
            format(values[key], "HH:mm");
        }

        return obj;
      }, {});

      setFormData(requestData);
    },
  });

  const [response, call, loading] = useAxios({
    ...apiMethod,
    data: formData,
  });

  useEffect(() => {
    groupCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    pduCall({ params: { skip_status: true, page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (groupResponse && groupResponse.status) {
      setGroups(groupResponse.data.data);
      setLabels(
        groupResponse.data.data.reduce(
          (obj, val) => ({ ...obj, [val.id]: val }),
          {
            0: { group_name: "None" },
          }
        )
      );
    }
  }, [groupResponse]);

  useEffect(() => {
    if (pduResponse && pduResponse.status) {
      setPdus(pduResponse.data.data);
      setPduLabels(
        pduResponse.data.data.reduce(
          (obj, val) => ({ ...obj, [val.id]: val }),
          {}
        )
      );
    }
  }, [pduResponse]);

  useEffect(() => {
    formik.setSubmitting(false);
    if (!response) return;
    if (response.status) {
      setAlertMessage({
        type: "success",
        message: `${isUpdate ? "Updated" : "Created"} successfully. ${
          response.data.id
        }`,
      });
      nav(`/gateway/${response.data.id}`);
    }
  }, [response]);

  useEffect(() => {
    if (formData) {
      call();
    }
  }, [formData]);

  if (groupApiLoading || pduApiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      {/* <Grid sx={{ flexGrow: 1 }} container spacing={0.5}>
        {isUpdate && (
          <Grid item xs={12}>
            <FormControl>
              <InputLabel htmlFor="id">ID</InputLabel>
              <OutlinedInput
                disabled
                nickname="id"
                label="ID"
                id="id"
                value={formik.values.id}
                onChange={(e) => e.preventDefault()}
              />
            </FormControl>
          </Grid>
        )}
      </Grid> */}

      <Grid
        sx={{ flexGrow: 1 }}
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth={350}
      >
        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.nickname && Boolean(formik.errors.nickname)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="nickname">Nickname</InputLabel>
            <OutlinedInput
              name="nickname"
              label="Nickname"
              id="nickname"
              value={formik.values.nickname}
              onChange={formik.handleChange}
            />
            {formik.touched.nickname && Boolean(formik.errors.nickname) ? (
              <FormHelperText id="nickname-error-text">
                {formik.errors.nickname}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.ip_address && Boolean(formik.errors.ip_address)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="ip_address">IP Address</InputLabel>
            <OutlinedInput
              name="ip_address"
              label="IP Address"
              id="ip_address"
              value={formik.values.ip_address}
              onChange={formik.handleChange}
            />
            {formik.touched.ip_address && Boolean(formik.errors.ip_address) ? (
              <FormHelperText id="ip_address-error-text">
                {formik.errors.ip_address}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.username && Boolean(formik.errors.username)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="username">Username</InputLabel>
            <OutlinedInput
              name="username"
              label="Username"
              id="username"
              value={formik.values.username}
              onChange={formik.handleChange}
            />
            {formik.touched.username && Boolean(formik.errors.username) ? (
              <FormHelperText id="username-error-text">
                {formik.errors.username}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.password && Boolean(formik.errors.password)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              name="password"
              label="Password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.touched.password && Boolean(formik.errors.password) ? (
              <FormHelperText id="password-error-text">
                {formik.errors.password}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.modems && Boolean(formik.errors.modems)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="modems">Modems</InputLabel>
            <OutlinedInput
              name="modems"
              label="Modems"
              id="modems"
              value={formik.values.modems}
              onChange={formik.handleChange}
              type="number"
            />
            {formik.touched.modems && Boolean(formik.errors.modems) ? (
              <FormHelperText id="modems-error-text">
                {formik.errors.modems}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.ports && Boolean(formik.errors.ports)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="ports">Ports</InputLabel>
            <OutlinedInput
              name="ports"
              label="Ports"
              id="ports"
              value={formik.values.ports}
              onChange={formik.handleChange}
              type="number"
            />
            {formik.touched.ports && Boolean(formik.errors.ports) ? (
              <FormHelperText id="ports-error-text">
                {formik.errors.ports}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.reboot_interval &&
              Boolean(formik.errors.reboot_interval)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="reboot_interval">
              Reboot Interval (days)
            </InputLabel>
            <OutlinedInput
              name="reboot_interval"
              label="Reboot Interval (days)"
              id="reboot_interval"
              value={formik.values.reboot_interval}
              onChange={formik.handleChange}
              type="number"
            />
            {formik.touched.reboot_interval &&
            Boolean(formik.errors.reboot_interval) ? (
              <FormHelperText id="reboot_interval-error-text">
                {formik.errors.reboot_interval}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} minWidth={"100%"}>
          <InputFieldAutoComplete
            isSelect
            options={[0, ...groups.map((group) => group.id)]}
            name="group_id"
            label="Group"
            id="group_id"
            getOptionLabel={(option) =>
              labels ? `${labels[option]?.group_name}` : option
            }
            value={formik.values.group_id}
            disabled={groups.length === 1}
            onChange={(_, val) => formik.setFieldValue("group_id", val)}
          />
        </Grid>

        <Grid item xs={12} minWidth={"100%"}>
          <InputFieldAutoComplete
            isSelect
            options={[...pdus.map((pdu) => pdu.id)]}
            name="pdu_id"
            label="PDU"
            id="pdu_id"
            getOptionLabel={(option) =>
              pduLabels ? `${pduLabels[option]?.host}` : option
            }
            value={formik.values.pdu_id}
            onChange={(_, val) => formik.setFieldValue("pdu_id", val)}
          />
        </Grid>
        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.pdu_load_id && Boolean(formik.errors.pdu_load_id)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="pdu_load_id">PDU Load Id</InputLabel>
            <OutlinedInput
              name="pdu_load_id"
              label="PDU Load Id"
              id="pdu_load_id"
              value={formik.values.pdu_load_id}
              onChange={formik.handleChange}
              type="number"
            />
            {formik.touched.pdu_load_id &&
            Boolean(formik.errors.pdu_load_id) ? (
              <FormHelperText id="pdu_load_id-error-text">
                {formik.errors.ports}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        {isUpdate && (
          <>
            <Grid item xs={12} minWidth={"100%"}>
              <FormControl
                error={
                  formik.touched.slot_rotation_schedule &&
                  Boolean(formik.errors.slot_rotation_schedule)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="Slot Rotation Time"
                    value={formik?.values?.slot_rotation_schedule && new Date(formik.values.slot_rotation_schedule)}
                    onChange={(newValue) =>
                      formik.setFieldValue("slot_rotation_schedule", newValue)
                    }
                  />
                </LocalizationProvider>
                {formik.touched.slot_rotation_schedule &&
                Boolean(formik.errors.slot_rotation_schedule) ? (
                  <FormHelperText id="slot_rotation_schedule-error-text">
                    {formik.errors.ports}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} minWidth={"100%"}>
              <FormControl
                error={
                  formik.touched.slot_rotation_wait_time &&
                  Boolean(formik.errors.slot_rotation_wait_time)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="slot_rotation_wait_time">
                  Slot Rotation Wait Time(in Seconds)
                </InputLabel>
                <OutlinedInput
                  name="slot_rotation_wait_time"
                  label="Slot Rotation Wait Time(in Seconds)"
                  id="slot_rotation_wait_time"
                  value={formik.values.slot_rotation_wait_time}
                  onChange={formik.handleChange}
                  type="number"
                />
                {formik.touched.slot_rotation_wait_time &&
                Boolean(formik.errors.slot_rotation_wait_time) ? (
                  <FormHelperText id="slot_rotation_wait_time-error-text">
                    {formik.errors.ports}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.note && Boolean(formik.errors.note) ? true : false
            }
            sx={{ width: "100%" }}
          >
            <TextField
              multiline
              rows={6}
              label="Note"
              defaultValue=""
              aria-label="minimum height"
              name="note"
              id="note"
              value={formik.values.note}
              onChange={formik.handleChange}
            />
            {formik.touched.note && Boolean(formik.errors.note) ? (
              <FormHelperText id="note-error-text">
                {formik.errors.note}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid
          alignItems="center"
          container
          sx={{ mt: 3 }}
          spacing={2}
          direction="row"
          justifyContent="center"
        >
          <Grid item>
            <LoadingButton
              style={{ marginRight: "5px" }}
              onClick={() =>
                !loading && !formik.isSubmitting && formik.submitForm()
              }
              size="large"
              loading={loading}
              variant="contained"
            >
              {isUpdate ? "Update" : "Create"}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              style={{ marginRight: "5px" }}
              size="large"
              onClick={() => nav("/gateway")}
              loading={loading}
              variant="contained"
            >
              Cancel
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GatewayForm;
