import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, InputLabel, OutlinedInput } from "@mui/material";

import Title from "../../common/Title";
import useAuth from "../../../hooks/useAuth";
import useAlert from "../../../hooks/useAlert";
import useAxios from "../../../hooks/useAxios";
import { __getUserList } from "../../../api/user";
import { __getGroups, __createUser } from "../../../api/group";
import InputFieldAutoComplete from "../../common/InputFieldAutoComplete";

const CreateUser = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  if (user.sub_user) {
    nav("/mdn");
  }

  const [userResponse, userCall, userApiLoading] = useAxios(__getUserList);
  const [groupResponse, groupCall, groupApiLoading] = useAxios(__getGroups);

  const { setAlertMessage } = useAlert();
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState();
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState(null);
  const [response, call, loading] = useAxios({
    ...__createUser,
  });
  const initialData = { password: "", username: "" };

  const validationSchema = yup.object({
    username: yup
      .string("username")
      .required("username is required")
      .min(4)
      .test(
        "user duplicate",
        "Username already exists",
        (value) =>
          value &&
          users.findIndex((curUser) => curUser.username === value) === -1
      ),
    password: yup.string("password").required("Password is required").min(4),
  });

  useEffect(() => {
    if (formData && (!user.admin || currentGroup?.id)) {
      call({
        data: {
          ...formData,
          group_id: user.admin ? currentGroup?.id : user.group_id,
        },
      });
    }
  }, [formData]);

  useEffect(() => {
    formik.setSubmitting(false);
    if (!response) return;
    if (response.status) {
      setAlertMessage({ type: "success", message: "User Created" });
      nav(`/group`);
    }
  }, [response]);

  useEffect(() => {
    userCall({ params: { page_size: 1000 } });
    user.admin && groupCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (userResponse && userResponse.status) {
      setUsers(userResponse.data.data);
    }
  }, [userResponse]);

  useEffect(() => {
    if (groupResponse && groupResponse.status) {
      setGroups(groupResponse.data.data);
      groupResponse.data.data.length === 1 &&
        setCurrentGroup(groupResponse.data.data[0]);
    }
  }, [groupResponse]);

  const formik = useFormik({
    initialValues: { ...initialData },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        obj[key] = values[key] || null;
        return obj;
      }, {});

      setFormData(requestData);
    },
    validateOnChange: true,
  });

  if (loading || userApiLoading || groupApiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%", mb: 5 }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title variant="h5" content="Create User" sx={{ mb: 3 }} gutterBottom />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={350}
        >
          {user !== 1 && (
            <Grid item xs={12} minWidth={"100%"}>
              <InputFieldAutoComplete
                isSelect
                options={[
                  ...groups.map((group) => ({
                    ...group,
                    label: `${group.group_name}`,
                  })),
                ]}
                name="group"
                label="Group"
                id="group"
                disabled={groups.length === 1}
                value={currentGroup?.group_name}
                isOptionEqualToValue={(option, value) =>
                  option && value && option.id === value.id
                }
                onChange={(e, value) => {
                  setCurrentGroup(value);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} minWidth={"100%"}>
            <FormControl
              error={Boolean(formik.errors.username) ? true : false}
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="username">Username</InputLabel>
              <OutlinedInput
                name="username"
                label="Username"
                id="username"
                handleBlur={formik.handleBlur}
                value={formik.values.username}
                onChange={formik.handleChange}
              />
              {Boolean(formik.errors.username) ? (
                <FormHelperText id="username-error-text">
                  {formik.errors.username}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} minWidth={"100%"}>
            <FormControl
              error={Boolean(formik.errors.password) ? true : false}
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                name="password"
                label="Password"
                id="password"
                handleBlur={formik.handleBlur}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {Boolean(formik.errors.password) ? (
                <FormHelperText id="password-error-text">
                  {formik.errors.password}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{ mt: 3 }}
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={
                  (user.admin && !currentGroup?.id) ||
                  formik.errors.username ||
                  formik.errors.password ||
                  !formik.values.password ||
                  !formik.values.username
                }
                onClick={() =>
                  (!user.admin || currentGroup?.id) &&
                  formik.values.password &&
                  formik.values.username &&
                  !loading &&
                  !formik.isSubmitting &&
                  formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                Create
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                size="large"
                onClick={() => nav("/group")}
                loading={loading}
                variant="contained"
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateUser;
