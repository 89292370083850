import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import {
  Grid,
  Radio,
  FormLabel,
  InputLabel,
  RadioGroup,
  FormControl,
  OutlinedInput,
  FormControlLabel,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Title from "../../common/Title";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AutoRotateModal({
  open,
  handleClose,
  handleSubmit,
  loading,
  selectedModel,
}) {
  const [enableRotation, setEnableRotation] = useState(true);
  const [onlineTime, setOnlineTime] = useState(5);
  const [timeout, setTimeout] = useState("true");
  const [enableUntil, setEnableUntil] = useState(null);

  useEffect(() => {
    setTimeout(enableRotation);
  }, [enableRotation]);

  const validDate = () =>
    !timeout || (enableUntil && enableUntil >= new Date());

  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Title variant="h5" content={`Auto Rotate Gateway Slot`} gutterBottom />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="enableRotation">Enable Auto Rotation</FormLabel>
              <RadioGroup
                row
                name="auto_rotate_group"
                value={enableRotation}
                onChange={(e) => setEnableRotation(e.target.value)}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Enable"
                />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="Disable"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {enableRotation && (
            <>
              <Grid item xs={12}>
                <InputLabel htmlFor="onlineTime">
                  Online Time (in minutes)
                </InputLabel>
                <OutlinedInput
                  name="onlineTime"
                  label="Rotate Online Time"
                  id="onlineTime"
                  value={onlineTime}
                  type="number"
                  onChange={(e) => setOnlineTime(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="timeout">Rotation Timeout</FormLabel>
                  <RadioGroup
                    row
                    name="timeout_group"
                    value={timeout}
                    onChange={(e) => setTimeout(e.target.value)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Enable"
                    />
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="Disable"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {timeout && (
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      id="enableUntil"
                      value={enableUntil || new Date()}
                      disablePast
                      onChange={(value) => setEnableUntil(value)}
                      slotProps={{
                        textField: {
                          helperText: "Select Future Time",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid container spacing={5} pt={1} justifyContent="left">
          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              disabled={loading || !validDate()}
              onClick={() => {
                const data = selectedModel.map((id) => {
                  const val = {
                    gateway_id: id,
                    enable_rotation: Boolean(enableRotation),
                  };
                  if (Boolean(enableRotation)) {
                    val.online_time = onlineTime;
                  }
                  if (timeout && enableUntil) {
                    val.enable_until = enableUntil?.getTime() / 1000;
                  }

                  return val;
                });
                handleSubmit({
                  data: data,
                });
              }}
            >
              {`Auto Rotate`}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
