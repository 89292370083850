import debounce from "lodash.debounce";
import fileDownload from "js-file-download";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import { gridFilteredSortedRowIdsSelector } from "@mui/x-data-grid";

import MdnList from "./MDNList";
import Title from "../../common/Title";
import Search from "../../common/search/Search";
import CustomSelect from "../../common/CustomSelect";

import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import { copyData } from "../../../utils/copy";
import { STATUS_MAPPING } from "../../../constants/value";
import {
  __exportMdns,
  __getMdns,
  __getMDNStatus,
  __resetPort,
} from "../../../api/mdn";

const Mdn = () => {
  const { user } = useAuth();
  const nav = useNavigate();
  const [mdns, setMdns] = useState([]);
  const [filteredMdns, setFilteredMdns] = useState([]);
  const [mdnStatus, setMdnStatus] = useState({});
  const [filters, setFilters] = useState({});
  const [filterStatus, setFilterStatus] = useState(false);
  const [queryOptions, setQueryOptions] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [sortQuery, setSortQuery] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [response, call, loading] = useAxios({ ...__getMdns });
  const [statusResponse, statusCall, statusLoading] = useAxios({
    ...__getMDNStatus,
  });
  const [exportResponse, exportCall, exportLoading] = useAxios({
    ...__exportMdns,
  });
  const [resetResponse, resetCall, resetLoading] = useAxios({});
  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  const handleFilterChange = React.useCallback((filterModel) => {
    const filterOption = filterModel?.items.length ? filterModel.items[0] : {};

    setFilters({
      filter_column: filterOption.columnField,
      filter_operator: filterOption.operatorValue,
      filter_value: filterOption.value ? filterOption.value : "",
    });
  }, []);

  useEffect(() => {
    debouncedFetchData({
      params: {
        page: currentPage,
        page_size: pageSize,
        search_query: searchValue,
        sort: sortQuery,
        ...queryOptions,
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, [currentPage, pageSize, searchValue, sortQuery, queryOptions]);

  useEffect(() => {
    if (response && response.status) {
      setMetaData(response.data.meta);
      setMdns(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    setFilteredMdns(
      user.id !== 1 && filterStatus
        ? mdns.filter(
            (val) =>
              (mdnStatus &&
                val.gateway_id &&
                val.port &&
                mdnStatus[val.gateway_id] &&
                mdnStatus[val.gateway_id][val.port] ===
                  STATUS_MAPPING[filterStatus]) ||
              val.status === STATUS_MAPPING[filterStatus]
          )
        : mdns
    );
  }, [mdns, filterStatus]);

  useEffect(() => {
    if (statusResponse && statusResponse.data) {
      setMdnStatus(statusResponse.data);
      setMdns(mdns);
    }
  }, [statusResponse]);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, "mdn_export.csv");
    }
  }, [exportResponse]);

  const handleFilterPanelClose = () => {
    setCurrentPage(1);
    setQueryOptions({ ...filters });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  const clearSearch = () => {
    setSearchValue("");
    setCurrentPage(1);
  };

  const handleSearchChange = (searchString) => {
    setSearchValue(searchString);
    setCurrentPage(1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="MDN" gutterBottom />
      {response && (
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <Stack sx={{ mb: "5px" }}>
              <Search
                searchValue={searchValue}
                clearSearch={clearSearch}
                changeSearchValue={handleSearchChange}
              />
            </Stack>
          </Grid>
          {user.id !== 1 && (
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    exportCall({
                      responseType: "blob",
                    })
                  }
                >
                  Export MDNs
                </Button>
              </Stack>
            </Grid>
          )}
          {user.id === 1 && (
            <>
              <Grid item>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => nav("add")}
                  >
                    Add SIMs
                  </Button>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => nav("mdn_export")}
                  >
                    MDN Exports
                  </Button>
                </Stack>
              </Grid>
            </>
          )}
          {!user.sub_user && (
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => nav("assign")}
                >
                  Sim Assignment List
                </Button>
              </Stack>
            </Grid>
          )}
          {user.sub_user && (
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => nav("alias")}
                >
                  Update Sim Alias
                </Button>
              </Stack>
            </Grid>
          )}

          {user.admin && user.id === 1 && (
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => nav("clear")}
                >
                  Clear MDNs
                </Button>
              </Stack>
            </Grid>
          )}
          {user.id !== 1 && (
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => nav("all")}
                >
                  View Status
                </Button>
              </Stack>
            </Grid>
          )}
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="success"
                onClick={() => nav("batch_reset")}
              >
                {"Batch Reset Port"}
              </Button>
            </Stack>
          </Grid>
          {!user.admin && !user.sub_user && (
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => nav("create-user")}
                >
                  Create New User
                </Button>
              </Stack>
            </Grid>
          )}
          {!user.admin && !user.sub_user && (
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => nav("update-user")}
                >
                  Update User
                </Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      )}
      {response ? (
        <MdnList
          mdns={filteredMdns}
          loading={loading || resetLoading}
          status={mdnStatus}
          statusLoading={statusLoading}
          meta={metaData}
          client={false}
          handleSort={setSortQuery}
          handleFilterChange={handleFilterChange}
          handlePageChange={handlePageChange}
          handleRefreshClick={() =>
            debouncedFetchData({
              params: {
                page: currentPage,
                page_size: pageSize,
                search_query: searchValue,
                sort: sortQuery,
                ...queryOptions,
              },
            })
          }
          handleHeaderClick={(key, apiRef) => {
            const gridIds = gridFilteredSortedRowIdsSelector(apiRef);
            copyData(
              filteredMdns.filter((val) => gridIds.includes(val.id)),
              [key]
            );
          }}
          handleResetCall={(id) => resetCall({ ...__resetPort(id) })}
          handlePageSizeChange={handlePageSizeChange}
          onPreferencePanelClose={(params, events, details) => {
            handleFilterPanelClose();
          }}
        />
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default Mdn;
