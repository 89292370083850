import { format } from "date-fns";
import fileDownload from "js-file-download";

import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, Grid, Stack } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/Download";

import Title from "../../common/Title";
import DataTable from "../../common/DataTable";

import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import {
  __getMDNExport,
  __getMDNExportList,
  __triggerMDNExport,
} from "../../../api/mdn";

const MDNExport = () => {
  const [exportList, setExportList] = useState([]);
  const { setAlertMessage } = useAlert();

  const [response, call, loading] = useAxios({ ...__getMDNExportList });
  const [fileResponse, fileCall, fileLoading] = useAxios({
    ...__getMDNExport,
  });
  const [triggerResponse, triggerCall, triggerLoading] = useAxios({
    ...__triggerMDNExport,
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setExportList(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (triggerResponse && triggerResponse.status) {
      setAlertMessage({
        type: "success",
        message: "New export started",
      });
      call();
    }
  }, [triggerResponse]);

  useEffect(() => {
    if (fileResponse && fileResponse.status) {
      fileDownload(fileResponse.data, `export-${new Date().toISOString()}.csv`);
    }

    if (fileResponse && !fileResponse.status) {
      setAlertMessage({
        type: "error",
        message: "File not found",
      });
    }
  }, [fileResponse]);

  const columns = [
    { field: "id", headerName: "ID", flex: 1, sortable: false },
    {
      field: "filename",
      headerName: "Filename",
      minWidth: 200,
      flex: 3,
      sortable: false,
    },
    { field: "status", headerName: "Status", flex: 2, sortable: false },
    {
      field: "created_at",
      headerName: "Created",
      minWidth: 200,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.created_at &&
        `${format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}`,
    },
    {
      field: "updated_at",
      headerName: "Updated",
      minWidth: 200,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.created_at &&
        `${format(new Date(params.row.updated_at), "MMMM d, y hh:mm a")}`,
    },
    {
      field: "action",
      headerName: "Download",
      minWidth: 100,
      flex: 2,
      renderCell: (params) =>
        params.row.status === "completed" ? (
          <FileDownloadIcon
            color="primary"
            onClick={() =>
              fileCall({
                responseType: "blob",
                url: `mdn/export_mdn/${params.row.id}`,
              })
            }
          />
        ) : null,
      sortable: false,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="MDN Export List" />
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item sx={{ mb: "5px" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              disabled={loading || triggerLoading || fileLoading}
              onClick={() => triggerCall()}
            >
              New Export
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <DataTable
        disableColumnFilter
        loading={loading}
        data={exportList}
        columns={columns}
        paginationMode={"client"}
        handleRowClick={null}
      />
    </Box>
  );
};

export default MDNExport;
