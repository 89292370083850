import fileDownload from "js-file-download";
import { endOfDay, startOfDay } from "date-fns";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, Grid } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import Title from "../../common/Title";
import { __exportSMS } from "../../../api/sms";
import useAxios from "../../../hooks/useAxios";
import CustomSelect from "../../common/CustomSelect";
import { statusOptions } from "../../../constants/value";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ExportModal({ open, handleClose }) {
  const [statusFilter, setStatusFilter] = useState({
    value: null,
    label: "Read/Unread",
  });
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [extractCodes, setExtractCodes] = useState(false);

  const [response, call, loading] = useAxios({ ...__exportSMS });

  useEffect(() => {
    if (response && response.status) {
      fileDownload(response.data, "sms_export.csv");
      handleClose(false);
    }
  }, [response]);

  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Title variant="h5" content="Export SMS Data" gutterBottom />
        <Grid container spacing={5}>
          <Grid item>
            <CustomSelect
              isClearable={false}
              isSearchable={false}
              closeMenuOnSelect={true}
              options={statusOptions.map((val) => ({
                ...val,
                label: val.value === null ? "Read/Unread" : val.label,
              }))}
              defaultValue={statusFilter}
              handleChange={(val) => setStatusFilter(val.value)}
            />
          </Grid>
          <Grid item style={{ mt: "8px" }}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={dateRange}
              open={true}
              clearIcon={null}
              onChange={(newVal) => setDateRange(newVal)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item style={{ marginLeft: "15px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={extractCodes}
                  onChange={(e) => setExtractCodes(e.target.checked)}
                />
              }
              label="Extract Codes"
            />
          </Grid>
          <Grid container spacing={5} justifyContent="left"></Grid>
          <Grid item>
            <LoadingButton
              style={{ marginLeft: "8px" }}
              variant="contained"
              color="primary"
              loading={loading}
              disabled={loading || !dateRange[0] || !dateRange[1]}
              onClick={() =>
                call({
                  responseType: "blob",
                  params: {
                    status: statusFilter.value,
                    start_date: startOfDay(dateRange[0]),
                    end_date: endOfDay(dateRange[1]),
                    extract_codes: extractCodes,
                  },
                })
              }
            >
              <FileDownloadIcon sx={{ mr: "5px" }} /> Export Data
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
