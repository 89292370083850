import { format } from "date-fns";
import debounce from "lodash.debounce";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import {
  Chip,
  alpha,
  Button,
  styled,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";

import Title from "../../common/Title";
import GatewayModal from "./GatewayModal";
import GatewayAtModal from "./GatewayAtModal";
import Search from "../../common/search/Search";

import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";

import {
  __save,
  __scan,
  __reboot,
  __rotateSlot,
  __pduPowerCycle,
  __runAtCommands,
  __autoSimRotate,
  __forbidPLMNCall,
  __getGatewaysOld,
  __getGatewaysStatus,
  __disable_not_in_use,
  __gatewayEnableSlots,
  __addSMSForwardSetting,
  __gatewayRebootInterval,
  __disableRotateSlot,
} from "../../../api/gateway";
import AutoRotateModal from "./AutoRotateModal";
import { gatewayOperation } from "../../../constants/value";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${gridClasses.row}`]: {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      cursor: "pointer",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const Gateway = () => {
  const nav = useNavigate();
  const { user } = useAuth();
  const [gateway, setGateway] = useState([]);
  const [gatewayStatus, setGatewayStatus] = useState({});
  const { setAlertMessage } = useAlert();
  const [selectionModel, setSelectionModel] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchValue, setSearchValue] = useState("");
  const [modalType, setModalType] = useState(false);
  const [atModal, setAtModal] = useState(false);
  const [autoRotateModal, setAutoRotateModal] = useState(false);
  const [response, call, loading] = useAxios({ ...__getGatewaysOld });
  const [statusResponse, statusCall, statusLoading] = useAxios({
    ...__getGatewaysStatus,
  });
  const [rebootResponse, rebootCall, rebootLoading] = useAxios({ ...__reboot });
  const [smsForwardResponse, smsForwardCall, smsForwardLoading] = useAxios({
    ...__addSMSForwardSetting,
  });
  const [forbidPlmnResponse, forbidPlmnCall, forbidPlmnLoading] = useAxios({
    ...__forbidPLMNCall,
  });
  const [atCommmandResponse, atCommmandCall, atCommmandLoading] = useAxios({
    ...__runAtCommands,
  });
  const [scanResponse, scanCall, scanLoading] = useAxios({ ...__scan });
  const [saveResponse, saveCall, saveLoading] = useAxios({ ...__save });
  const [rotateSlotResponse, rotateSlotCall, rotateSlotLoading] = useAxios({
    ...__rotateSlot,
  });
  const [
    disableRotateSlotResponse,
    disableRotateSlotCall,
    disableRotateSlotLoading,
  ] = useAxios({
    ...__disableRotateSlot,
  });
  const [disableResponse, disableCall, disableLoading] = useAxios({
    ...__disable_not_in_use,
  });
  const [enableResponse, enableCall, enableLoading] = useAxios({
    ...__gatewayEnableSlots,
  });
  const [cycleResponse, cycleCall, cycleLoading] = useAxios({
    ...__pduPowerCycle,
  });
  const [autoRotateResponse, autoRotateCall, autoRotateLoading] = useAxios({
    ...__autoSimRotate,
  });
  const [setRebootResponse, setRebootCall, setRebootLoading] = useAxios({
    ...__gatewayRebootInterval,
  });

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    if (response && response.status) {
      setGateway(response.data.data);
      if (response.data.data && response.data.data.length)
        statusCall({
          params: { ids: response.data.data.map((val) => val.id) },
        });
    }
  }, [response]);

  useEffect(() => {
    if (cycleResponse && cycleResponse.status) {
      setAlertMessage({
        type: "success",
        message: "PDU operation initiated successfully",
      });
    }
  }, [cycleResponse]);

  useEffect(() => {
    debouncedFetchData({
      params: {
        page_size: 1000,
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  useEffect(() => {
    if (statusResponse) {
      setGatewayStatus(statusResponse.data);
    }
  }, [statusResponse]);

  useEffect(() => {
    setGateway(gateway);
  }, [gatewayStatus]);

  const clearSearch = () => {
    setSearchValue("");
    setCurrentPage(1);
  };

  const handleSearchChange = (searchString) => {
    setSearchValue(searchString);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (rebootResponse && rebootResponse.status) {
      const status = rebootResponse.data.every(
        (val) => val.is_reboot_successful
      )
        ? "success"
        : rebootResponse.data.every((val) => !val.is_reboot_successful)
        ? "error"
        : "info";
      setAlertMessage({
        type: status,
        message: (
          <>
            {rebootResponse.data.map((val) =>
              val?.is_reboot_successful ? (
                <p>{`Success rebooting for ${val?.nickname} gateway.`}</p>
              ) : (
                <p>{`Failed rebooting setting for ${val?.nickname} gateway.`}</p>
              )
            )}
          </>
        ),
      });
      setSelectionModel([]);
    }
  }, [rebootResponse]);

  useEffect(() => {
    if (scanResponse && scanResponse.status) {
      setAlertMessage({
        type: "success",
        message: scanResponse.data?.message,
      });
      setModalType(false);
      setSelectionModel([]);
    }
  }, [scanResponse]);

  useEffect(() => {
    if (setRebootResponse && setRebootResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Updated gateways",
      });
      setModalType(false);
      setSelectionModel([]);
    }
  }, [setRebootResponse]);

  useEffect(() => {
    if (rotateSlotResponse && rotateSlotResponse.status) {
      setAlertMessage({
        type: "success",
        message: rotateSlotResponse.data?.message,
      });
      setModalType(false);
      setSelectionModel([]);
    }
  }, [rotateSlotResponse]);

  useEffect(() => {
    if (disableRotateSlotResponse && disableRotateSlotResponse.status) {
      setAlertMessage({
        type: "success",
        message: disableRotateSlotResponse.data?.message,
      });
      setModalType(false);
      setSelectionModel([]);
    }
  }, [disableRotateSlotResponse]);

  useEffect(() => {
    if (autoRotateResponse && autoRotateResponse.status) {
      setAlertMessage({
        type: "success",
        message: (
          <>
            {autoRotateResponse?.data?.map((val) =>
              val?.is_save_successful ? (
                <p>{`Success saving ${val?.nickname} gateway.`}</p>
              ) : (
                <p>{`Failed saving ${val?.nickname} gateway.`}</p>
              )
            )}
          </>
        ),
      });
      setAutoRotateModal(false);
      setSelectionModel([]);
    }
  }, [autoRotateResponse]);

  useEffect(() => {
    if (saveResponse && saveResponse.status) {
      const status = saveResponse.data.every((val) => val.is_save_successful)
        ? "success"
        : saveResponse.data.every((val) => !val.is_save_successful)
        ? "error"
        : "info";
      setAlertMessage({
        type: status,
        message: (
          <>
            {saveResponse.data.map((val) =>
              val?.is_save_successful ? (
                <p>{`Success saving setting for ${val?.nickname} gateway.`}</p>
              ) : (
                <p>{`Failed saving setting for ${val?.nickname} gateway.`}</p>
              )
            )}
          </>
        ),
      });
      setSelectionModel([]);
    }
  }, [saveResponse]);

  useEffect(() => {
    if (disableResponse && disableResponse.status) {
      setAlertMessage({
        type: "success",
        message: (
          <>
            {disableResponse.data.map((val) => (
              <p>{val}</p>
            ))}
          </>
        ),
      });
      setSelectionModel([]);
    }
  }, [disableResponse]);

  useEffect(() => {
    if (enableResponse && enableResponse.status) {
      setAlertMessage({
        type: "success",
        message: enableResponse.data && JSON.stringify(enableResponse.data),
      });
      setModalType(false);
      setSelectionModel([]);
    }
  }, [enableResponse]);

  useEffect(() => {
    if (smsForwardResponse && smsForwardResponse.status) {
      const status = smsForwardResponse.data.every(
        (val) => val.is_save_successful
      )
        ? "success"
        : smsForwardResponse.data.every((val) => !val.is_save_successful)
        ? "error"
        : "info";
      setAlertMessage({
        type: status,
        message: (
          <>
            {smsForwardResponse.data.map((val) =>
              val?.is_save_successful ? (
                <p>{`Success forward sms setting for ${val?.nickname} gateway.`}</p>
              ) : (
                <p>{`Failed forward sms setting for ${val?.nickname} gateway.`}</p>
              )
            )}
          </>
        ),
      });
      setModalType(false);
      setSelectionModel([]);
    }
  }, [smsForwardResponse]);

  useEffect(() => {
    if (forbidPlmnResponse && forbidPlmnResponse.status) {
      const status = forbidPlmnResponse.data.every(
        (val) => val.is_save_successful
      )
        ? "success"
        : forbidPlmnResponse.data.every((val) => !val.is_save_successful)
        ? "error"
        : "info";
      setAlertMessage({
        type: status,
        message: (
          <>
            {forbidPlmnResponse.data.map((val) =>
              val?.is_save_successful ? (
                <p>{`Success forbid PLMN call for ${val?.nickname} gateway.`}</p>
              ) : (
                <p>{`Failed forbid PLMN call for ${val?.nickname} gateway.`}</p>
              )
            )}
          </>
        ),
      });
      setModalType(false);
      setSelectionModel([]);
    }
  }, [forbidPlmnResponse]);

  useEffect(() => {
    if (atCommmandResponse && atCommmandResponse.status) {
      setAlertMessage({
        type: "success",
        message: atCommmandResponse.data && atCommmandResponse.data.message,
      });
      setAtModal(false);
      setSelectionModel([]);
      nav(`gateway-actions/${atCommmandResponse.data.batch_id}`);
    }
  }, [atCommmandResponse]);

  const modalProperties = {
    [gatewayOperation.Enable]: {
      handleSubmit: enableCall,
      loading: enableLoading,
    },
    [gatewayOperation.Scan]: {
      handleSubmit: scanCall,
      loading: scanLoading,
    },
    [gatewayOperation.Rotate]: {
      handleSubmit: rotateSlotCall,
      secondarySubmit: disableRotateSlotCall,
      loading: rotateSlotLoading || disableRotateSlotLoading,
    },
    [gatewayOperation.SetReboot]: {
      handleSubmit: setRebootCall,
      loading: setRebootLoading,
    },
  };

  const rebootDevices = (selectedIds) => {
    rebootCall({ data: { ids: selectedIds } });
  };

  const saveDevices = (selectedIds) => {
    saveCall({ data: { ids: selectedIds } });
  };

  const disableDevices = (selectedIds) => {
    disableCall({ data: { ids: selectedIds } });
  };

  const addSMSForwardSettings = (selectedIds) => {
    smsForwardCall({ data: { ids: selectedIds } });
  };

  const forbidPlmn = (selectedIds) => {
    forbidPlmnCall({ data: { ids: selectedIds } });
  };

  const gateway_table_superadmin = [
    { field: "id", headerName: "ID", width: 70, flex: 1 },
    { field: "nickname", headerName: "Nickname", flex: 2, minWidth: 200 },
    {
      field: "ip_address",
      headerName: "Host",
      flex: 3,
      minWidth: 250,
      renderCell: (params) => (
        <div onClick={(e) => e.stopPropagation()}>
          <a href={`http://${params.row.ip_address}`} target="_blank">
            {params.row.ip_address}
          </a>
        </div>
      ),
    },
    { field: "username", headerName: "Username", flex: 1, minWidth: 50 },
    {
      field: "password",
      headerName: "Password",
      flex: 2,
      minWidth: 200,
      renderCell: (params) => (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>{params.row.password}</Grid>
          <Grid item>
            <Tooltip title="Copy Password">
              <IconButton
                onClick={(e) => {
                  const valueToCopy = params.row.password;
                  navigator.clipboard.writeText(valueToCopy);
                  e.stopPropagation();
                }}
                size="small"
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ),
    },
    { field: "modems", headerName: "Modems", flex: 1 },
    { field: "ports", headerName: "Ports", flex: 1 },
    {
      field: "is_online",
      headerName: "Status",
      flex: 1,
      sortable: true,
      filterable: false,
      valueGetter: (params) => gatewayStatus[params.row.id],
      renderCell: (params) => {
        if (statusLoading || loading) {
          return <LoadingButton loading={true} />;
        }
        return (
          <Chip
            label={gatewayStatus[params.row.id] ? "Online" : "Offline"}
            size="small"
            color={gatewayStatus[params.row.id] ? "secondary" : "primary"}
          />
        );
      },
    },
    { field: "reboot_interval", headerName: "Reboot Cycle (Days)", flex: 1 },
    {
      field: "last_reboot",
      headerName: "Last Reboot",
      minWidth: 100,
      flex: 2,
      renderCell: (params) => (
        <Typography>
          {params.row.last_reboot &&
            format(new Date(params.row.last_reboot), "MMMM d, y hh:mm a")}
        </Typography>
      ),
    },
    {
      field: "note",
      headerName: "Notes",
      // minWidth: 250,
      flex: 2,
      sortable: false,
      filterable: false,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 400,
      flex: 5,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Stack spacing={1} direction="row">
          <Button
            onClick={(e) => {
              nav(`${params.row.id}/details`);
              e.stopPropagation();
            }}
          >
            Details
          </Button>
          {params.row.pdu_id && params.row.pdu_load_id && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  cycleCall({ url: `gateway/${params.row.id}/cycle_power` });
                }}
              >
                Power Cycle
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={(e) => {
                  e.stopPropagation();
                  cycleCall({ url: `gateway/${params.row.id}/on_power` });
                }}
              >
                On
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  cycleCall({ url: `gateway/${params.row.id}/off_power` });
                }}
              >
                Off
              </Button>
            </>
          )}
        </Stack>
      ),
    },
  ];

  const gateway_table = [
    { field: "id", headerName: "ID", width: 70, flex: 1 },
    { field: "nickname", headerName: "Nickname", flex: 2, minWidth: 150 },
    {
      field: "ip_address",
      headerName: "Host",
      flex: 3,
      minWidth: 250,
      renderCell: (params) => (
        <div onClick={(e) => e.stopPropagation()}>
          <a href={`http://${params.row.ip_address}`} target="_blank">
            {params.row.ip_address}
          </a>
        </div>
      ),
    },
    { field: "username", headerName: "Username", flex: 1, minWidth: 50 },
    {
      field: "password",
      headerName: "Password",
      flex: 2,
      minWidth: 200,
      renderCell: (params) => (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>{params.row.password}</Grid>
          <Grid item>
            <Tooltip title="Copy Password">
              <IconButton
                onClick={(e) => {
                  const valueToCopy = params.row.password;
                  navigator.clipboard.writeText(valueToCopy);
                  e.stopPropagation();
                }}
                size="small"
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ),
    },
    { field: "modems", headerName: "Modems", flex: 1 },
    { field: "ports", headerName: "Ports", flex: 1 },
    {
      field: "is_online",
      headerName: "Status",
      flex: 1,
      sortable: true,
      filterable: false,
      valueGetter: (params) => gatewayStatus[params.row.id],
      renderCell: (params) => {
        if (statusLoading || loading) {
          return <LoadingButton loading={true} />;
        }
        return (
          <Chip
            label={gatewayStatus[params.row.id] ? "Online" : "Offline"}
            size="small"
            color={gatewayStatus[params.row.id] ? "secondary" : "primary"}
          />
        );
      },
    },
    { field: "reboot_interval", headerName: "Reboot Cycle (Days)", flex: 1 },
    {
      field: "last_reboot",
      headerName: "Last Reboot",
      minWidth: 100,
      flex: 2,
      renderCell: (params) => (
        <Typography>
          {params.row.last_reboot &&
            format(new Date(params.row.last_reboot), "MMMM d, y hh:mm a")}
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 250,
      flex: 5,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Stack spacing={1} direction="row">
          <Button
            onClick={(e) => {
              nav(`${params.row.id}/details`);
              e.stopPropagation();
            }}
          >
            Details
          </Button>
          {params.row.pdu_id && params.row.pdu_load_id && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  cycleCall({ url: `gateway/${params.row.id}/cycle_power` });
                }}
              >
                Power Cycle
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={(e) => {
                  e.stopPropagation();
                  cycleCall({ url: `gateway/${params.row.id}/on_power` });
                }}
              >
                On
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  cycleCall({ url: `gateway/${params.row.id}/off_power` });
                }}
              >
                Off
              </Button>
            </>
          )}
        </Stack>
      ),
    },
  ];

  const getGetWayData = () => {
    if (!searchValue || !searchValue.length || !gateway || !gateway.length) {
      return gateway;
    }
    return gateway.filter((val) => {
      if (
        val.ip_address.toLowerCase().indexOf(searchValue.toLowerCase()) !==
          -1 ||
        val.nickname.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  };

  const isLoading = () => {
    return (
      loading ||
      rebootLoading ||
      scanLoading ||
      saveLoading ||
      disableLoading ||
      rotateSlotLoading ||
      enableLoading ||
      cycleLoading ||
      smsForwardLoading ||
      forbidPlmnLoading ||
      atCommmandLoading ||
      autoRotateLoading ||
      setRebootLoading ||
      disableRotateSlotLoading
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="Gateway" gutterBottom />
      {response && (
        <>
          {modalType && Object.keys(gatewayOperation).includes(modalType) && (
            <GatewayModal
              gatewayData={gateway}
              handleClose={setModalType}
              handleSubmit={modalProperties[modalType]?.handleSubmit}
              loading={modalProperties[modalType]?.loading}
              secondarySubmit={modalProperties[modalType]?.secondarySubmit}
              operation={modalType}
              selectedModel={selectionModel}
            />
          )}
          {atModal && (
            <GatewayAtModal
              gatewayData={gateway}
              handleClose={setAtModal}
              handleSubmit={atCommmandCall}
              isLoading={() => isLoading()}
              openAtModal={atModal}
              selectedModel={selectionModel}
            />
          )}
          {autoRotateModal && (
            <AutoRotateModal
              handleClose={setAutoRotateModal}
              handleSubmit={autoRotateCall}
              isLoading={() => isLoading()}
              open={autoRotateCall}
              selectedModel={selectionModel}
            />
          )}
          <Grid
            container
            justifyContent="flex-start"
            spacing={2}
            sx={{ mb: "5px" }}
          >
            <Grid item>
              <Stack spacing={1} direction="row">
                {user.id === 1 && (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      disabled={
                        !selectionModel || !selectionModel.length || isLoading()
                      }
                      onClick={() => addSMSForwardSettings(selectionModel)}
                    >
                      Add SMS forward settings
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      disabled={
                        !selectionModel || !selectionModel.length || isLoading()
                      }
                      onClick={() => forbidPlmn(selectionModel)}
                    >
                      Forbid PLMN Call
                    </Button>
                  </>
                )}
                <Button
                  variant="contained"
                  color="success"
                  disabled={isLoading()}
                  onClick={() => nav("/scan_history")}
                >
                  Scan History
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  disabled={isLoading()}
                  onClick={() => nav("/rotate_history")}
                >
                  Rotate Slot History
                </Button>
              </Stack>
            </Grid>
            {user.id === 1 && (
              <Grid item>
                <Stack spacing={1} direction="row">
                  <Button
                    variant="contained"
                    color="success"
                    disabled={isLoading()}
                    onClick={() => nav("create")}
                  >
                    Create
                  </Button>
                </Stack>
              </Grid>
            )}
            <Grid item>
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={
                    !selectionModel || !selectionModel.length || isLoading()
                  }
                  onClick={() => setModalType(gatewayOperation.Enable)}
                >
                  Enable Slots
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={
                    !selectionModel || !selectionModel.length || isLoading()
                  }
                  onClick={() => disableDevices(selectionModel)}
                >
                  Disable Not In Use Slots
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={
                    !selectionModel || !selectionModel.length || isLoading()
                  }
                  onClick={() => saveDevices(selectionModel)}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={
                    !selectionModel || !selectionModel.length || isLoading()
                  }
                  onClick={() => rebootDevices(selectionModel)}
                >
                  Reboot
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={
                    !selectionModel || !selectionModel.length || isLoading()
                  }
                  onClick={() => setModalType(gatewayOperation.SetReboot)}
                >
                  Set Reboot Interval
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={
                    !selectionModel || !selectionModel.length || isLoading()
                  }
                  onClick={() => setModalType(gatewayOperation.Scan)}
                >
                  Scan
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !selectionModel || !selectionModel.length || isLoading()
                  }
                  onClick={() => setModalType(gatewayOperation.Rotate)}
                >
                  Rotate Slots
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !selectionModel || !selectionModel.length || isLoading()
                  }
                  onClick={() => setAtModal(true)}
                >
                  Run AT Commands
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !selectionModel || !selectionModel.length || isLoading()
                  }
                  onClick={() => setAutoRotateModal(true)}
                >
                  Auto Rotate
                </Button>
              </Stack>
            </Grid>
            {user.id === 1 && (
              <Grid item>
                <Stack spacing={1} direction="row">
                  <Link className="cell-link" to={"sms_test"}>
                    <Button variant="contained" color="primary">
                      SMS Test History
                    </Button>
                  </Link>
                </Stack>
              </Grid>
            )}
            {(user.id === 1 || user.address_list) && (
              <Grid item>
                <Stack spacing={1} direction="row">
                  <Link className="cell-link" to={"address_rules"}>
                    <Button variant="contained" color="primary">
                      IP Whitelist
                    </Button>
                  </Link>
                </Stack>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item>
              <Stack sx={{ my: "10px" }}>
                <Search
                  searchValue={searchValue}
                  clearSearch={clearSearch}
                  changeSearchValue={handleSearchChange}
                />
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      {response ? (
        <>
          <StripedDataGrid
            rows={getGetWayData()}
            columns={user.id === 1 ? gateway_table_superadmin : gateway_table}
            onRowClick={
              user.id === 1 ? (e, details) => nav(`${e.row.id}`) : null
            }
            checkboxSelection
            onSelectionModelChange={(itm) => setSelectionModel(itm)}
            selectionModel={selectionModel}
            autoHeight
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            initialState={{
              pagination: {
                pageSize: 10,
              },
            }}
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default Gateway;
