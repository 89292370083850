import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";

const Home = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  useEffect(() => {
    if (!user) {
      nav("/login");
    }

    nav("/sms");
  }, []);

  return <></>;
};

export default Home;
