import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Title from "../../common/Title";
import GatewayForm from "./GatewayForm";
import useAxios from "../../../hooks/useAxios";
import { __getGatewayById } from "../../../api/gateway";

const GatewayUpdate = () => {
  const [gateway, setGateway] = useState([]);
  const { gateway_id } = useParams();
  const [response, call, apiLoading] = useAxios({
    ...__getGatewayById(gateway_id),
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setGateway(response.data);
    }
  }, [response]);

  if (apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box
      sx={{ width: "100%" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Title
        variant="h5"
        content="Gateway Update"
        sx={{ mb: 3 }}
        gutterBottom
      />
      <GatewayForm
        mode="update"
        initialValues={gateway}
        gatewayId={gateway.id}
      />
    </Box>
  );
};

export default GatewayUpdate;
