import * as React from "react";

import Select from "react-select";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";

export default function CustomSelect({
  options,
  helperText,
  placeholder,
  handleChange,
  handleMenuOpen,
  handleMenuClose,
  ...props
}) {
  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: props.minWidth || 150, mt: 0 }}>
        <Select
          closeMenuOnSelect={false}
          options={options}
          onChange={handleChange}
          placeholder={placeholder}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          {...props}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
}
