import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import { Chip } from "@mui/material";

import Title from "../../../common/Title";
import DataTable from "../../../common/DataTable";

import useAxios from "../../../../hooks/useAxios";
import { __smsTestHistory } from "../../../../api/gateway";

const SMSReceiveTest = () => {
  const [smsTestHistory, setsmsTestHistory] = useState([]);
  const nav = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__smsTestHistory });
  const handleRowClick = (e) => {
    nav(`${e.row.batch_id}`);
  };

  const columns = [
    {
      field: "batch_id",
      headerName: "Batch ID",
      flex: 3,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => <div>{params.row.batch_id}</div>,
    },
    {
      field: "gateway_id",
      headerName: "Gateway Id",
      minWidth: 120,
      flex: 2,
      sortable: false,
      renderCell: (params) => <div>{params.row.gateway_id || "-"}</div>,
    },
    {
      field: "gateway",
      headerName: "Gateway",
      minWidth: 120,
      flex: 2,
      sortable: false,
      renderCell: (params) => <div>{params.row.gateway || "-"}</div>,
    },
    {
      field: "processed",
      headerName: "Processed",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.processed ? "Yes" : "No"}
          size="small"
          color={params.row.processed ? "success" : "error"}
        />
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row.created_at &&
            `${format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}`}
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Last Updated",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row.updated_at &&
            `${format(new Date(params.row.updated_at), "MMMM d, y hh:mm a")}`}
        </div>
      ),
    },
  ];

  useEffect(() => {
    call({
      params: {
        page: currentPage,
        page_size: pageSize,
      },
    });
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.size,
        total: response.data.total,
      });
      setsmsTestHistory(response.data.data);
    }
  }, [response]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="Sms Test History" gutterBottom />
      {response ? (
        <>
          <DataTable
            loading={loading}
            data={smsTestHistory}
            columns={columns}
            metaData={metaData}
            handleRowClick={handleRowClick}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default SMSReceiveTest;
