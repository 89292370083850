import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, Stack } from "@mui/material";

import RemoveModal from "./RemoveModal";
import Title from "../../../common/Title";
import AddAddressModal from "./AddAddressModal";
import DataTable from "../../../common/DataTable";

import useAlert from "../../../../hooks/useAlert";
import useAxios from "../../../../hooks/useAxios";
import {
  __addAddressRules,
  __getGroups,
  __listRules,
  __removeAddressRules,
} from "../../../../api/group";
import useAuth from "../../../../hooks/useAuth";

const AddressRule = () => {
  const { user } = useAuth();
  const { setAlertMessage } = useAlert();
  const [modal, setModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [rules, setRules] = useState([]);
  const [groups, setGroups] = useState([]);

  const [response, call, loading] = useAxios({ ...__listRules });
  const [removeResponse, removeCall, removeLoading] = useAxios({
    ...__removeAddressRules,
  });
  const [addResponse, addCall, addLoading] = useAxios({
    ...__addAddressRules,
  });
  const [groupResponse, groupCall, groupLoading] = useAxios({ ...__getGroups });

  const columns = [
    { field: ".id", headerName: "ID", flex: 1 },
    { field: "list", headerName: "List", flex: 2 },
    { field: "address", headerName: "IP Address", flex: 2 },
    { field: "creation-time", headerName: "Created At", flex: 2 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => (
        <Stack spacing={1} direction="row">
          <Button
            color="error"
            variant="contained"
            onClick={(e) => {
              setRemoveModal(params.row);
            }}
          >
            Remove
          </Button>
        </Stack>
      ),
    },
  ];
  const adminColumns = [
    { field: "address", headerName: "IP Address", flex: 2 },
    { field: "creation-time", headerName: "Created At", flex: 2 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => (
        <Stack spacing={1} direction="row">
          <Button
            color="error"
            variant="contained"
            onClick={(e) => {
              setRemoveModal(params.row);
            }}
          >
            Remove
          </Button>
        </Stack>
      ),
    },
  ];
  useEffect(() => {
    call();
    groupCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (groupResponse && groupResponse.status) {
      setGroups(groupResponse.data.data);
    }
  }, [groupResponse]);

  useEffect(() => {
    if (response && response.status) {
      setRules(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (addResponse && addResponse.status) {
      setAlertMessage({
        type: "success",
        message: "Added new whitelist",
      });
      setModal(false);
      call();
    }
  }, [addResponse]);

  useEffect(() => {
    if (removeResponse && removeResponse.status) {
      setAlertMessage({
        type: "success",
        message: `Deleted rule ${removeModal[".id"]}`,
      });
      setRemoveModal(null);
      call();
    }
  }, [removeResponse]);

  const isLoading = () =>
    loading || addLoading || groupLoading || removeLoading;

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="IP whitelist" gutterBottom />
      <Button
        variant="contained"
        color="success"
        onClick={() => setModal(true)}
      >
        Add new whitelist
      </Button>

      {modal && (
        <AddAddressModal
          handleClose={setModal}
          handleSubmit={addCall}
          isLoading={() => isLoading()}
          open={modal}
          groups={groups}
        />
      )}

      {removeModal && (
        <RemoveModal
          handleClose={setRemoveModal}
          handleSubmit={removeCall}
          isLoading={() => isLoading()}
          open={removeModal}
          rule={removeModal}
        />
      )}

      {response ? (
        <>
          <DataTable
            loading={loading}
            data={rules}
            columns={user.id === 1 ? columns : adminColumns}
            getRowId={(row) => row[".id"]}
            paginationMode="client"
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default AddressRule;
