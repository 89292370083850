import { format } from "date-fns";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import { Box, Chip, alpha, Typography, gridClasses } from "@mui/material";

import Title from "../../../common/Title";

import useAxios from "../../../../hooks/useAxios";
import { __smsTestDetails } from "../../../../api/gateway";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${gridClasses.row}`]: {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      cursor: "pointer",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const columns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "port", headerName: "Port", flex: 1 },
  { field: "mdn", headerName: "MDN", flex: 1 },
  { field: "message", headerName: "Message", flex: 3 },
  { field: "message_id", headerName: "Matching ID", flex: 1 },
  {
    field: "matched",
    headerName: "Matched",
    flex: 1,
    renderCell: (params) => (
      <Chip
        label={params.row.message_id ? "Yes" : "No"}
        size="small"
        color={params.row.message_id ? "success" : "error"}
      />
    ),
  },
  {
    field: "processed",
    headerName: "Processed",
    flex: 1,
    renderCell: (params) => (
      <Chip
        label={params.row.processed ? "Yes" : "No"}
        size="small"
        color={params.row.processed ? "success" : "error"}
      />
    ),
  },
  { field: "twilio_message_sid", headerName: "Twilio ID", flex: 2 },
  { field: "twilio_message_status", headerName: "Twilio Status", flex: 1 },
  {
    field: "updated_at",
    headerName: "Updated At",
    minWidth: 200,
    flex: 2,
    renderCell: (params) => (
      <Typography>
        {format(new Date(params.row.updated_at), "MMMM d, y hh:mm a")}
      </Typography>
    ),
  },
];

const SMSReceiveDetails = (props) => {
  const [smsDetails, setSmsDetails] = useState([]);
  const { batch_id } = useParams();

  const [response, call, loading] = useAxios({
    ...__smsTestDetails(batch_id),
  });

  useEffect(() => {
    if (response && response.status) {
      setSmsDetails(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    call();
  }, []);

  return loading ? (
    <Title content="Please wait..." variant="subtitle2" gutterBottom />
  ) : (
    <>
      <Box mb={2} pt={2}>
        <Typography variant="h5" align="left">
          SMS Test -
          {smsDetails?.length && smsDetails[0].gateway_id
            ? ` Gateway: ${smsDetails?.length && smsDetails[0].gateway}`
            : null}
          {` Gateway Id: ${
            smsDetails?.length && smsDetails[0].gateway_id
          } Batch ID: ${smsDetails?.length && smsDetails[0].batch_id}`}
        </Typography>
      </Box>

      <StripedDataGrid
        rows={smsDetails}
        columns={columns}
        disableSelectionOnClick
        autoHeight
      />
    </>
  );
};

export default SMSReceiveDetails;
