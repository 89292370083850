import React, { useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, InputLabel, OutlinedInput } from "@mui/material";

import useAuth from "../../../../hooks/useAuth";

import Title from "../../../common/Title";
import CustomSelect from "../../../common/CustomSelect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddAddressModal({
  open,
  handleClose,
  handleSubmit,
  loading,
  groups,
}) {
  const { user } = useAuth();
  const [ipAddress, setIpAddress] = useState("");
  const [groupId, setGroupId] = useState();

  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Title variant="h5" content={`Add new Rules`} gutterBottom />
        <Grid container spacing={5}>
          <Grid item>
          <FormControl
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="ip_address">IP Address</InputLabel>
            <OutlinedInput
              name="ip_address"
              label="IP Address"
              id="ip_address"
              value={ipAddress}
              onChange={(e) => setIpAddress(e.target.value)}
            />
          </FormControl>
          </Grid>
          {user.id === 1 && (
            <Grid item>
              <CustomSelect
                helperText={"Group"}
                placeholder={"Group"}
                isClearable={false}
                isSearchable={false}
                closeMenuOnSelect={true}
                options={groups
                  .filter((val) => val.address_list)
                  .map((val) => ({ value: val.id, label: val.group_name }))}
                handleChange={(val) => setGroupId(val.value)}
              />
            </Grid>
          )}
        </Grid>

        <Grid container>
          <Grid container spacing={5} justifyContent="left"></Grid>
          <Grid item>
            <LoadingButton
              style={{ marginLeft: "8px" }}
              variant="contained"
              color="primary"
              loading={loading}
              disabled={
                loading || !ipAddress.trim() || (user.id === 1 && !groupId)
              }
              onClick={() =>
                handleSubmit({
                  data: {
                    group_id: groupId,
                    ip_address: ipAddress,
                  },
                })
              }
            >
              {`Add new whitelist`}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
