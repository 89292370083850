import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import StripedDataGrid from "../../common/StripedDataGrid";

import useAxios from "../../../hooks/useAxios";
import { __fetchGatewayActions } from "../../../api/gateway";

const columns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "gateway_id", headerName: "Gateway ID", flex: 1 },
  { field: "modem", headerName: "Modem", flex: 1 },
  { field: "request_payload", headerName: "Request Payload", flex: 3 },
  { field: "response", headerName: "Response", flex: 3 },
  { field: "status", headerName: "Status", flex: 2 },
  {
    field: "last_modified",
    headerName: "Last Modified",
    flex: 3,
    valueGetter: (params) =>
      params.row.last_modified_at &&
      `${new Date(params.row.last_modified_at).toLocaleString()}`,
  },
];

const GatewayActions = () => {
  const { batch_id } = useParams();

  const [gatewayActions, setGatewayActions] = useState([]);
  const [response, call, loading] = useAxios({
    ...__fetchGatewayActions(batch_id),
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setGatewayActions(response.data);
    }
  }, [response]);

  return (
    <>
      <Box mb={2} pt={2}>
        <Typography variant="h5" align="left">
          {`Gateway Actions, Batch ID: ${batch_id}`}
        </Typography>
      </Box>
      <StripedDataGrid
        loading={loading}
        rows={gatewayActions}
        columns={columns}
        autoHeight
      />
    </>
  );
};

export default GatewayActions;
