import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";

import PDUList from "./PDUList";
import Title from "../../../common/Title";

import useAxios from "../../../../hooks/useAxios";
import { __getPDUs, __getPDUStatus } from "../../../../api/gateway";

const PDU = () => {
  const nav = useNavigate();
  const [pdus, setPdus] = useState([]);
  const [pduStatus, setPduStatus] = useState({});
  const [response, call, loading] = useAxios({ ...__getPDUs });
  const [statusResponse, statusCall, statusLoading] = useAxios({
    ...__getPDUStatus,
  });

  useEffect(() => {
    call({
      params: {
        page_size: 1000,
      },
    });
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setPdus(response.data.data);
      if (response.data.data && response.data.data.length) {
        statusCall({
          params: { ids: response.data.data.map((val) => val.id) },
        });
      }
    }
  }, [response]);

  useEffect(() => {
    if (statusResponse && statusResponse.data) {
      setPduStatus(statusResponse.data);
    }
  }, [statusResponse]);

  useEffect(() => {
    setPdus(pdus);
  }, [pduStatus]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="PDU" gutterBottom />
      {response && (
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="success"
                onClick={() => nav("create")}
              >
                Create New PDU
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
      {response ? (
        <>
          <PDUList
            pdus={pdus}
            status={pduStatus}
            statusLoading={statusLoading}
            loading={loading}
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default PDU;
