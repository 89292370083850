import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import GroupForm from "./GroupForm";
import Title from "../../common/Title";
import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import useAuth from "../../../hooks/useAuth";
import {
  __getGroupById,
  __updateGroup,
  __updateGroupSuperAdmin,
} from "../../../api/group";

const GroupUpdate = () => {
  const { user } = useAuth();
  const nav = useNavigate();
  const [group, setGroup] = useState([]);
  const { setAlertMessage } = useAlert();
  const { group_id } = useParams();
  const [response, call, apiLoading] = useAxios({
    ...__getGroupById(group_id),
  });
  const [updateResponse, updateCall, updateApiLoading] = useAxios(
    user.id === 1
      ? {
          ...__updateGroupSuperAdmin(group_id),
        }
      : { ...__updateGroup(group_id) }
  );

  if (!user.admin) {
    nav("/group");
  }

  useEffect(() => {
    call();
  }, []);

  const handleFormSubmit = (data) => {
    updateCall({ data: data });
  };

  useEffect(() => {
    if (response && response.status) {
      setGroup(response.data);
      return;
    }

    if (response && !response.status) {
      nav("/group");
    }
  }, [response]);

  useEffect(() => {
    if (!updateResponse) return;
    if (updateResponse.status) {
      setAlertMessage({ type: "success", message: `Updated successfully.` });

      call();
    }
  }, [updateResponse]);

  if (apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box
      sx={{ width: "100%" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Title variant="h5" content="Group Update" sx={{ mb: 3 }} gutterBottom />
      <GroupForm
        mode="update"
        initialValues={group}
        groupId={group.id}
        handleSubmit={handleFormSubmit}
        onlyAdmin={user.id !== 1}
        loading={apiLoading || updateApiLoading}
      />
    </Box>
  );
};

export default GroupUpdate;
