import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import { MenuItem, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

import { PDU_TYPES } from "../../../../constants/value";

const PDUForm = ({ initialValues, mode, pduId, handleSubmit, loading }) => {
  const initialData = {
    host: "",
    username: "",
    password: null,
    type: "",
  };
  const isUpdate = mode === "update" && pduId;
  const nav = useNavigate();
  const [formData, setFormData] = useState(null);

  const createValidationSchema = yup.object({
    host: yup
      .string("host")
      .required("Host is required")
      .max(128, "Cannot be more than 128 characters long."),
    username: yup
      .string("username")
      .required("Username is required")
      .max(64, "Cannot be more than 64 characters long."),
    password: yup
      .string("password")
      .required("password is required")
      .max(64, "Cannot be more than 64 characters long."),
    type: yup
      .string("type")
      .required("type is required")
      .test((val) => PDU_TYPES.includes(val)),
  });

  const updateValidationSchema = yup.object({
    host: yup
      .string("host")
      .max(128, "Cannot be more than 128 characters long."),
    username: yup
      .string("username")
      .max(64, "Cannot be more than 64 characters long."),
    password: yup
      .string("password")
      .max(64, "Cannot be more than 64 characters long."),
    type: yup.string("type").test((val) => PDU_TYPES.includes(val)),
  });

  const validationSchema = isUpdate
    ? updateValidationSchema
    : createValidationSchema;

  const formik = useFormik({
    initialValues:
      initialValues && Object.keys(initialValues).length
        ? { ...initialValues }
        : { ...initialData },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (
          Object.keys(validationSchema.fields).includes(key) &&
          values[key] !== null
        ) {
          obj[key] = values[key];
        }
        return obj;
      }, {});

      setFormData(requestData);
    },
  });

  useEffect(() => {
    if (formData) {
      handleSubmit(formData);
      if (!loading) {
        formik.setSubmitting(false);
      }
    }
  }, [formData]);

  return (
    <>
      <Grid
        sx={{ flexGrow: 1 }}
        container
        spacing={2}
        rowSpacing={2}
        direction="column"
        alignItems="center"
        maxWidth={350}
      >
        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.host && Boolean(formik.errors.host) ? true : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="host">Host</InputLabel>
            <OutlinedInput
              name="host"
              label="Host"
              id="host"
              value={formik.values.host}
              onChange={formik.handleChange}
              sx={{ maxWidth: "100%" }}
            />
            {formik.touched.host && Boolean(formik.errors.host) ? (
              <FormHelperText id="host-error-text">
                {formik.errors.host}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.username && Boolean(formik.errors.username)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="username">Username</InputLabel>
            <OutlinedInput
              name="username"
              label="Username"
              id="username"
              value={formik.values.username}
              onChange={formik.handleChange}
            />
            {formik.touched.username && Boolean(formik.errors.username) ? (
              <FormHelperText id="username-error-text">
                {formik.errors.username}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.password && Boolean(formik.errors.password)
                ? true
                : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              name="password"
              label="Password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.touched.password && Boolean(formik.errors.password) ? (
              <FormHelperText id="password-error-text">
                {formik.errors.password}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} minWidth={"100%"}>
          <FormControl
            error={
              formik.touched.type && Boolean(formik.errors.type) ? true : false
            }
            sx={{ width: "100%" }}
          >
            <InputLabel id="pdu_type">Type</InputLabel>
            <Select
              options={PDU_TYPES}
              name="type"
              label="Type"
              id="type"
              value={formik.values.type}
              onChange={formik.handleChange}
            >
              {PDU_TYPES.map((val) => (
                <MenuItem value={val}>{val}</MenuItem>
              ))}
            </Select>
            {formik.touched.type && Boolean(formik.errors.type) ? (
              <FormHelperText id="type-error-text">
                {formik.errors.type}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        sx={{ flexGrow: 1, mt: 2 }}
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <LoadingButton
            style={{ marginRight: "5px" }}
            onClick={() =>
              !loading && !formik.isSubmitting && formik.submitForm()
            }
            disabled={!!Object.keys(formik.errors).length}
            size="large"
            loading={loading}
            variant="contained"
          >
            {isUpdate ? "Update" : "Create"}
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            style={{ marginRight: "5px" }}
            size="large"
            onClick={() => nav("/pdu")}
            loading={loading}
            variant="contained"
          >
            Cancel
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default PDUForm;
