import React from "react";

import { Chip, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { matchCodes } from "../../utils/data";

const StyledChip = styled(Chip)(({ theme }) => ({}));

const CopyCode = ({ text }) => {
  const [show, setShow] = React.useState(false);
  return (
    <Tooltip
      title="Copied!"
      aria-label="Copied!"
      open={show}
      disableHoverListener
      onMouseLeave={() => setShow(false)}
    >
      <StyledChip
        sx={{ ml: 1, mr: 1 }}
        icon={<ContentCopyIcon />}
        label={text}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          navigator.clipboard.writeText(text);
        }}
      >
        {text}
      </StyledChip>
    </Tooltip>
  );
};

export default function CodeChip({ text }) {
  const segmenter = new Intl.Segmenter([], {
    granularity: "word",
  });
  const parts = Array.from(segmenter.segment(text)).map((part) => part.segment);
  const regexMatches = matchCodes(text);
  const finalText = [];
  const reducer = parts.reduce((acc, part, index) => {
    if (regexMatches.includes(part)) {
      finalText.push(acc);
      finalText.push(<CopyCode key={`${part}-${index}`} text={part} />);
      return "";
    }

    acc = acc + part;
    return acc;
  }, []);
  finalText.push(reducer);

  return finalText;
}
