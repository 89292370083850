import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import { Box, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import Title from "../../common/Title";
import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import { __clearMdns } from "../../../api/mdn";

const MDNClear = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  if (!user.admin) {
    nav("/mdn");
  }

  const { setAlertMessage } = useAlert();
  const [rawData, setRawData] = useState("");
  const [linesData, setLinesData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [response, call, loading] = useAxios({
    ...__clearMdns,
  });
  const initialData = { lines: [] };

  useEffect(() => {
    if (formData) {
      call({
        data: { ...formData, lines: linesData },
      });
    }
  }, [formData]);

  useEffect(() => {
    formik.setSubmitting(false);
    if (!response) return;
    if (response.status) {
      setAlertMessage({
        type: "success",
        message: "Cleared mdns successfully",
      });
      nav(`/mdn`);
    }
    formik.setErrors(validateFunction(rawData));
  }, [response]);

  const validateFunction = (data) => {
    const errors = {};

    if (!data) {
      return errors;
    }

    const lines = data.split("\n");
    lines.some((line, idx) => {
      try {
        if (isNaN(line.trim())) {
          errors.lines = `Invalid number in line ${idx + 1}: ${line}`;
          return true;
        }
        if (line.trim().length > 20 || line.trim().length < 19) {
          throw new Error(`Invalid number in line ${idx + 1}: ${line}`);
        }
      } catch (err) {
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      return false;
    });

    if (!errors.lines) {
      setLinesData(lines.map((line) => line.trim()));
    } else {
      setLinesData([]);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { ...initialData },
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        obj[key] = values[key] || null;
        return obj;
      }, {});

      setFormData(requestData);
    },
    validateOnChange: true,
  });

  if (loading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%", mb: 5 }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title
          variant="h5"
          content="Clears MDNs from lines"
          sx={{ mb: 3 }}
          gutterBottom
        />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={350}
        >
          <Grid item xs={12} minWidth={"100%"}>
            <InputLabel htmlFor="lines">
              {"Add in the format"}
              <br></br>
              {"[ICCID]"}
              <br></br>
              {"[ICCID]"}
            </InputLabel>
            <FormControl
              error={Boolean(formik.errors.lines) ? true : false}
              sx={{ width: "100%" }}
            >
              <TextField
                multiline
                rows={6}
                defaultValue="Default Value"
                aria-label="minimum height"
                placeholder={`Add in the format\n[ICCID]\n[ICCID]`}
                name="lines"
                id="lines"
                value={rawData}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const data = e.target.value;
                  setRawData(data);
                  formik.setErrors(validateFunction(data));
                }}
              />
              {Boolean(formik.errors.lines) ? (
                <FormHelperText id="lines-error-text">
                  {formik.errors.lines}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <LoadingButton
                disabled={formik.errors.lines || !linesData?.length}
                onClick={() =>
                  !loading && !formik.isSubmitting && formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                {"Clear MDNs"}
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                size="large"
                onClick={() => nav("/mdn")}
                loading={loading}
                variant="contained"
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MDNClear;
