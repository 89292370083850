export const ATT = "att";

export const JASPER = "jasper";

export const JASPER_STATUS = {
  ACTIVATED: "ACTIVATED",
  DEACTIVATED: "DEACTIVATED",
};

export const statusOptions = [
  { value: null, label: "Inbox" },
  { value: 1, label: "Unread" },
  { value: 0, label: "Read" },
  { value: -1, label: "Archived" },
];

export const STATUS_MAPPING = {
  0: "Not inserted",
  1: "Sim inserted",
  2: "Registering",
  3: "Registered",
  4: "Talking",
  5: "No balance or alarm",
  6: "Sim card register fail",
  7: "Port disabled",
  8: "Sim card is locked",
  9: "Sim card error",
  11: "Not connected",
  12: "Operator lock",
  13: "User lock",
  14: "Sim Problem",
  15: "Module Problem",
  16: "Response Timeout",
};

export const getStatusLabel = (status) => {
  if (status === null) return "Not Found";

  return STATUS_MAPPING[status] || status;
};

export const getStatusChipColor = (status) => {
  switch (status) {
    case "0":
    case "1":
    case "11":
      return "default";
    case "2":
      return "warning";
    case "3":
      return "success";
    case "4":
      return "primary";
    default:
      return "error";
  }
};

export const getStatusChipColorLabel = (status) => {
  if (!status) {
    return "error";
  }
  switch (status.toUpperCase()) {
    case "NOT INSERTED":
    case "SIM INSERTED":
    case "NOT CONNECTED":
      return "default";
    case "REGISTERING":
      return "warning";
    case "REGISTERED":
      return "success";
    case "TALKING":
      return "primary";
    default:
      return "error";
  }
};

export const gatewayOperation = {
  Enable: "Enable",
  Scan: "Scan",
  Rotate: "Rotate",
  SetReboot: "SetReboot",
};

export const SLOT_MAPPING = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 10,
  K: 11,
  L: 12,
  M: 13,
  N: 14,
  O: 15,
  P: 16,
  Q: 17,
  R: 18,
  S: 19,
  T: 20,
  U: 21,
  V: 22,
  W: 23,
  X: 24,
  Y: 25,
  Z: 26,
  AA: 27,
  BB: 28,
  CC: 29,
  DD: 30,
  EE: 31,
  FF: 32,
};

export const PENDING_SLOT_STATUS = ["IN_PROGRESS", "IN_QUEUE"];

export const PDU_TYPES = ["old", "new", "basic"];
