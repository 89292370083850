import debounce from "lodash.debounce";
import useWebSocket from "react-use-websocket";
import React, { useCallback, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import DraftsIcon from "@mui/icons-material/Drafts";
import CachedIcon from "@mui/icons-material/Cached";
import { Button, useMediaQuery } from "@mui/material";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import SMSTable from "./SMSTable";
import Title from "../../common/Title";
import ExportModal from "./ExportModal";
import SMSTableMobile from "./SMSTableMobile";
import Search from "../../common/search/Search";
import CustomSelect from "../../common/CustomSelect";

import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import { url } from "../../../config/environment";
import { getFromStorage } from "../../../utils/storage";
import { __getGatewaysOld } from "../../../api/gateway";
import { statusOptions } from "../../../constants/value";
import { __bulkUpdate, __getSms, __updateSmsStatus } from "../../../api/sms";

const Sms = () => {
  const { user } = useAuth();
  const [sms, setSms] = useState([]);
  const matches = useMediaQuery("(min-width:600px)");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    last_page: true,
  });
  const [currentBulkAction, setCurrentBulkAction] = useState(null);
  const [sortQuery, setSortQuery] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [currentGateways, setCurrentGateways] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);

  const accessToken = getFromStorage("ACCESS_TOKEN_DATA");

  const socketUrl = `${url.socketUrl}?access_token=${accessToken}`;

  const { lastJsonMessage } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (
      currentPage === 1 &&
      pageSize === 100 &&
      sortQuery === null &&
      searchValue === "" &&
      (!selectedIds || !selectedIds.length) &&
      (!currentGateways || !currentGateways.length) &&
      statusFilter === null
    )
      setSms([lastJsonMessage, ...sms.slice(0, -1)]);
  }, [lastJsonMessage]);

  const [modalOpen, setModalOpen] = useState(false);

  const [response, call, loading] = useAxios({ ...__getSms });
  const [updateResponse, updateCall, updateLoading] = useAxios({
    ...__updateSmsStatus,
  });
  const [gatewaysResponse, gatewayCall, gatewayLoading] = useAxios({
    ...__getGatewaysOld,
  });
  const [bulkUpdateResponse, bulkUpdate, bulkUpdateLoading] = useAxios({
    ...__bulkUpdate,
  });

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  const clearAllFilters = () => {
    setCurrentPage(1);
    setPageSize(100);
    setSortQuery(null);
    setSearchValue("");
    setSelectedIds([]);
    setCurrentGateways([]);
    setMenuOpen(false);
    setStatusFilter(null);
  };

  const debounceCall = useCallback(() => {
    debouncedFetchData({
      params: {
        page: currentPage,
        page_size: pageSize,
        search_query: searchValue,
        sort: sortQuery,
        gateways: currentGateways,
        status: statusFilter,
      },
    });
  }, [
    currentPage,
    pageSize,
    searchValue,
    sortQuery,
    currentGateways,
    statusFilter,
  ]);

  useEffect(() => {
    !menuOpen && debounceCall();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [
    currentPage,
    pageSize,
    searchValue,
    sortQuery,
    currentGateways,
    statusFilter,
    debounceCall,
  ]);

  const setProperSms = (smsList) =>
    setSms(
      smsList.filter(
        (sms) =>
          (statusFilter === null && sms.processed !== -1) ||
          statusFilter === sms.processed
      )
    );
  const updateExistingSMS = (ids, updateStatus, data) =>
    data.map((val) =>
      ids.includes(val.id) ? { ...val, processed: updateStatus } : { ...val }
    );

  useEffect(() => {
    if (response && response.status) {
      setMetaData(response.data.meta);
      setProperSms(response.data.data);
      setSelectedIds([]);
    }
  }, [response]);

  useEffect(() => {
    user.admin && gatewayCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (bulkUpdateResponse && bulkUpdateResponse.status) {
      setProperSms(updateExistingSMS(selectedIds, currentBulkAction, sms));
      setSelectedIds([]);
      setCurrentBulkAction(null);
    }
  }, [bulkUpdateResponse]);

  useEffect(() => {
    if (updateResponse && updateResponse.status) {
      const updatedData = updateResponse.data;
      setProperSms(
        sms.map((val) => ({
          ...val,
          processed:
            val.id === updatedData.id ? updatedData.processed : val.processed,
        }))
      );
    }
  }, [updateResponse]);

  useEffect(() => {
    if (gatewaysResponse && gatewaysResponse.status) {
      setGateways(gatewaysResponse.data.data);
    }
  }, [gatewaysResponse]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = sms.map((n) => n.id);
      setSelectedIds(newSelected);
      return;
    }
    setSelectedIds([]);
  };

  const handleSelectionChange = (id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelected = newSelected.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    setSelectedIds(newSelected);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  const clearSearch = () => {
    setSearchValue("");
    setCurrentPage(1);
  };

  const handleSearchChange = (searchString) => {
    setSearchValue(searchString);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectedIds && selectedIds.length && currentBulkAction !== null)
      bulkUpdate({
        data: { status: currentBulkAction, ids: [...selectedIds] },
      });
  }, [currentBulkAction]);

  const handleRowClick = (row) => {
    if (statusFilter !== -1)
      updateCall({
        url: `/sms/${row.id}/status`,
        data: { status: row.processed === 1 ? 0 : 1 },
      });
  };

  if (!response) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <ExportModal open={modalOpen} handleClose={setModalOpen} />
      <Grid container spacing={2} sx={{ mb: "14px" }}>
        <Grid item>
          <Title variant="h5" content="SMS" gutterBottom />
        </Grid>
        <Grid item>
          <Stack>
            <Button
              variant="contained"
              color="primary"
              disabled={loading || gatewayLoading || bulkUpdateLoading}
              onClick={() => debounceCall()}
            >
              <CachedIcon sx={{ mr: "5px" }} /> Refresh
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <Stack>
            <Button
              variant="contained"
              color="primary"
              disabled={loading || gatewayLoading || bulkUpdateLoading}
              onClick={() => setModalOpen(true)}
            >
              <FileDownloadIcon sx={{ mr: "5px" }} /> Export
            </Button>
          </Stack>
        </Grid>
        {!(
          currentPage === 1 &&
          pageSize === 100 &&
          sortQuery === null &&
          searchValue === "" &&
          (!selectedIds || !selectedIds.length) &&
          (!currentGateways || !currentGateways.length) &&
          statusFilter === null
        ) && (
          <Grid item>
            <Stack>
              <Button
                variant="contained"
                color="primary"
                disabled={loading || gatewayLoading || bulkUpdateLoading}
                onClick={() => clearAllFilters()}
              >
                <FilterAltOffIcon sx={{ mr: "5px" }} /> Resume Live Feed
              </Button>
            </Stack>
          </Grid>
        )}
        <Grid item alignSelf={"right"}>
          <Stack>
            <a
              className="cell-link"
              href="https://drive.google.com/file/d/1gPkr4o80NNo4xSXU1hxKs-dFf82FsAgh/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="contained" color="primary">
                Download Extension
              </Button>
            </a>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item width={300} sx={{ mb: "14px", mr: "50px" }}>
          <Stack>
            <Search
              searchValue={searchValue}
              clearSearch={clearSearch}
              changeSearchValue={handleSearchChange}
            />
          </Stack>
        </Grid>
        <Grid item>
          <CustomSelect
            isClearable={false}
            isSearchable={false}
            closeMenuOnSelect={true}
            options={statusOptions}
            defaultValue={statusOptions[0]}
            value={statusOptions.filter((val) => val.value === statusFilter)[0]}
            handleChange={(val) => setStatusFilter(val.value)}
          />
        </Grid>
        {user.admin && (
          <Grid item>
            <CustomSelect
              isMulti
              options={gateways.map((gateway) => ({
                value: gateway.id,
                label: gateway.id,
              }))}
              value={currentGateways.map((val) => ({
                value: val,
                label: val,
              }))}
              handleChange={(val) =>
                setCurrentGateways(val.map((v) => v.value))
              }
              placeholder={"All Gateways"}
              handleMenuOpen={() => setMenuOpen(true)}
              handleMenuClose={() => {
                setMenuOpen(false);
                debounceCall();
              }}
            />
          </Grid>
        )}
      </Grid>
      <Divider sx={{ mb: "14px" }} />
      <Grid container spacing={2} sx={{ mb: "5px" }}>
        <Grid item>
          <Stack>
            <Button
              variant="contained"
              color="success"
              disabled={
                !(selectedIds && selectedIds.length) || bulkUpdateLoading
              }
              onClick={() => setCurrentBulkAction(1)}
            >
              <DraftsIcon sx={{ mr: "5px" }} /> Mark Read
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <Stack>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !(selectedIds && selectedIds.length) || bulkUpdateLoading
              }
              onClick={() => setCurrentBulkAction(0)}
            >
              <EmailIcon sx={{ mr: "5px" }} /> Mark Unread
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <Stack>
            {statusFilter === -1 ? (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !(selectedIds && selectedIds.length) || bulkUpdateLoading
                }
                onClick={() => setCurrentBulkAction(0)}
              >
                <UnarchiveIcon sx={{ mr: "5px" }} />
                UnArchive
              </Button>
            ) : (
              <Button
                variant="contained"
                color="error"
                disabled={
                  !(selectedIds && selectedIds.length) || bulkUpdateLoading
                }
                onClick={() => setCurrentBulkAction(-1)}
              >
                <UnsubscribeIcon sx={{ mr: "5px" }} />
                Archive
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>

      {matches ? (
        <SMSTableMobile
          sms={sms}
          meta={metaData}
          loading={loading}
          handleSort={setSortQuery}
          onRowClick={handleRowClick}
          selectedItems={selectedIds}
          handlePageChange={handlePageChange}
          onSelectionChange={handleSelectionChange}
          handleAllSelectClick={handleSelectAllClick}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <SMSTable
          sms={sms}
          meta={metaData}
          loading={loading}
          handleSort={setSortQuery}
          onRowClick={handleRowClick}
          selectedItems={selectedIds}
          handlePageChange={handlePageChange}
          onSelectionChange={handleSelectionChange}
          handleAllSelectClick={handleSelectAllClick}
          handlePageSizeChange={handlePageSizeChange}
        />
      )}
    </Box>
  );
};

export default Sms;
