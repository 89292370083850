import React from "react";

import Box from "@mui/material/Box";

import Title from "../../common/Title";
import GatewayForm from "./GatewayForm";

const GatewayCreate = () => {
  return (
    <Box
      sx={{ width: "100%" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Title
        variant="h5"
        content="Gateway Update"
        sx={{ mb: 3 }}
        gutterBottom
      />
      <GatewayForm mode="create" />
    </Box>
  );
};

export default GatewayCreate;
