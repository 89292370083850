import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Title from "../../../common/Title";
import RotateSlotList from "./RotateSlotList";
import useAxios from "../../../../hooks/useAxios";
import CustomSelect from "../../../common/CustomSelect";
import {
  __getGatewaysOld,
  __getRotateSlotHistory,
} from "../../../../api/gateway";

const RotateSlotHistory = () => {
  const [gateways, setGateways] = useState([]);
  const [currentGateways, setCurrentGateways] = useState([]);
  const [rotateSlotHistory, setrotateSlotHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__getRotateSlotHistory });
  const [gatewaysResponse, gatewayCall, gatewayLoading] = useAxios({
    ...__getGatewaysOld,
  });

  useEffect(() => {
    !menuOpen &&
      call({
        params: {
          page: currentPage,
          page_size: pageSize,
          gateway_id: currentGateways,
        },
      });
  }, [currentPage, pageSize, currentGateways, menuOpen]);

  useEffect(() => {
    gatewayCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (gatewaysResponse && gatewaysResponse.status) {
      setGateways(gatewaysResponse.data.data);
    }
  }, [gatewaysResponse]);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.size,
        total: response.data.total,
      });
      setrotateSlotHistory(response.data.items);
    }
  }, [response]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="Rotate Slot History" gutterBottom />
      <CustomSelect
        isMulti
        options={gateways.map((gateway) => ({
          value: gateway.id,
          label: gateway.id,
        }))}
        currentValue={currentGateways}
        handleChange={(val) => setCurrentGateways(val.map((v) => v.value))}
        placeholder={"All Gateways"}
        handleMenuOpen={() => setMenuOpen(true)}
        handleMenuClose={() => {
          setMenuOpen(false);
        }}
      />
      {response ? (
        <>
          <RotateSlotList
            rotateSlotHistory={rotateSlotHistory}
            meta={metaData}
            loading={loading}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            triggerCall={() =>
              call({
                params: {
                  page: currentPage,
                  page_size: pageSize,
                  gateway_id: currentGateways,
                },
              })
            }
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default RotateSlotHistory;
