import * as React from "react";

import Popover from "@mui/material/Popover";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import Search from "../../common/search/Search";

const SearchIconContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  opacity: 1,
  color: "gray",
  cursor: "pointer",
  transition: "opacity 0.2s ease-in-out, background-color 0.2s ease-in-out",
  marginRight: "20px",
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
  },
});

const LabelContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "5px",
});

export default function ColumnSearchHeader({
  loading,
  label,
  searchPlaceholderValue,
  currentFilter,
  handleSearchChange,
  keyName,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <LabelContainer>
      <span>{label}</span>
      <Tooltip title={`Click to search ${searchPlaceholderValue || label}`}>
        <SearchIconContainer
          onClick={handleClick}
          className="searchIcon"
          style={{ color: currentFilter && currentFilter[keyName] && "#6FDA44" }}
        >
          <SearchIcon />
        </SearchIconContainer>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Search
          searchValue={
            currentFilter && currentFilter[keyName]
              ? currentFilter[keyName]
              : ""
          }
          loading={loading}
          changeSearchValue={(value) => handleSearchChange(keyName, value)}
          clearSearch={() => handleSearchChange(keyName, "")}
          hideAdornment={true}
          placeholder={`Search ${searchPlaceholderValue || label}`}
        />
      </Popover>
    </LabelContainer>
  );
}
