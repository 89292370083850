import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Title from "../../../common/Title";
import ScanHistoryList from "./ScanHistoryList";
import useAxios from "../../../../hooks/useAxios";
import { __getScanHistory } from "../../../../api/gateway";

const ScanHistory = () => {
  const [scanHistory, setScanHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__getScanHistory });

  useEffect(() => {
    call({
      params: {
        page: currentPage,
        page_size: pageSize,
      },
    });
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (response && response.status) {
      setMetaData(response.data.meta);
      setScanHistory(response.data.data);
    }
  }, [response]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content="Scan History" gutterBottom />
      {response ? (
        <>
          <ScanHistoryList
            scanHistory={scanHistory}
            meta={metaData}
            loading={loading}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            triggerCall={() =>
              call({
                params: {
                  page: currentPage,
                  page_size: pageSize,
                },
              })
            }
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default ScanHistory;
